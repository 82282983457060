import React, {useState, useRef} from 'react';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import { useClasses } from 'contexts/classes';
import { TableSetupProvider } from 'contexts/tableSetup';
import ClassDetails from './classDetails';

const classColumns = [
    {id: "id", label:"Number", display: true, style:{width:'50px'}, datum: "id"},
    {id: "section", label: "Section", display: true, style:{width:'50px'}, datum: "section"},
    {id: "description", label: "Description", display: true, datum: "description"},
    {id: "hidden", label: "Hidden", display: true, formatter: obj => obj.hidden ? 'Yes' : 'No'}
];

function Classes() {
    const { classes, deleteClass } = useClasses();
    const [editingClass, setEditingClass] = useState(null);
    const sidePanel = useRef(null);

    const showClassDialog = (val) => {
        setEditingClass(val);
        sidePanel.current.show();
    }

    const deleteClassesHandler = (classes) => {
        let ids = classes.map((c) => c.id);
        deleteClass(ids);
    }

    const hidePanelHandler = () => {
        setEditingClass(null);
    }

    return (
        <div className='communication-area'>
            <TableSetupProvider tableName='classes' defaultColumns={classColumns}>
                <AmazingTable 
                    rows={classes}
                    onCreate={() => showClassDialog(null)}
                    onCreateLabel='Add class'
                    onDelete={deleteClassesHandler}
                    onRowClick={(row) => showClassDialog(row)}
                />
            </TableSetupProvider>
            <SidePanel title='Class' ref={sidePanel} onHide={hidePanelHandler}>
                    <ClassDetails 
                        carClass={editingClass} 
                        onSave={() => {setEditingClass(null);sidePanel.current.hide();}}/>
            </SidePanel>
        </div>
    )
}

export default Classes;
import React, { useState } from 'react';
import { TableSetupProvider } from 'contexts/tableSetup';
import AmazingTable from 'components/amazingTable';
import Modal from 'components/modal';
import EventTicketOption from './eventTicketOption';

const cols = [
    {id: 'id', label:'ID', display: false, datum: 'id'},
    {id: 'eventTicketId', label:'Ticket ID', display: false, datum: 'eventTicketId'},
    {id: 'type', label: 'Type', style:{width:'70px'}, display: true, formatter: obj => {return obj.type === 1 ? 'Text' : 'Choice'}},
    {id: 'label', label: 'Label', style:{width:'100px'}, display: true, datum: 'label'},
    {id: 'required', label: 'Req', style:{width:'50px'}, display: true, formatter: obj => {return obj.required ? 'Yes' : 'No'} },
];

function EventTicketOptions({options, onChange}) {
    const [showOption, setShowOption] = useState(false);
    const [option, setOption] = useState(null);

    const onCreateHandler = () => {
        setOption(null);
        setShowOption(true);
    }

    const editHandler = (opt) => {
        setOption(opt);
        setShowOption(true);
    }

    const saveOptionHandler = (opt) => {
        setShowOption(false);
        if (option) {
            // update
            const optionIndex = options.findIndex(o => o.id === option.id);
            const data = {...option, ...opt}
            const newOptions = options.toSpliced(optionIndex, 1, data);
            onChange(newOptions);
        } else {
            // create
            const maxId = Math.max(...options.map(o => o.id));
            opt.id = maxId + 1;
            opt.isNew = true;
            const opts = [...options, opt];
            onChange(opts);
        }
    }

    const removeOptionsHandler = (opts) => {
        const newOpts = [...options];
        newOpts.forEach(opt => {
            if (opts.findIndex(o => o.id === opt.id) > -1) {
                opt.deleteMe = true;
                if (opt.isNew) {
                    opt.isNew = false;
                }
            }
        })
        onChange(newOpts);
    }

    return (
        <div className='table-setting'>
            <TableSetupProvider tableName='eventticketoptions' defaultColumns={cols}>
                <AmazingTable 
                    rows={options.filter(opt => !opt.deleteMe)}
                    onRowClick={editHandler}
                    onCreate={onCreateHandler}
                    onCreateLabel='Add option'
                    onDelete={removeOptionsHandler} />
            </TableSetupProvider>
            <Modal title='Ticket option' showing={showOption} onHide={() => setShowOption(false)}>
                <EventTicketOption option={option} onSave={saveOptionHandler}/>
            </Modal>
        </div>
    )
}

export default EventTicketOptions;
import React, { useRef, useState } from 'react';
import {isSupported} from 'util/contentType';
import { publish } from 'pubsub-js';
import 'style/avatar.scss';
import 'style/imageManager.scss';

function HiddenCanvas({onChange, width, height, buttonTitle}) {
    const fileInput = useRef(null);
    const imageCanvas = useRef(null);

    const selectImage = () => {
        fileInput.current.click();
    }

    const handleFileSelection = (evt) => {
        var files = evt.target.files;
		// eslint-disable-next-line no-cond-assign
        for (var i = 0, f; f = files[i]; i++) {
            var name = escape(f.name);
            console.log("File Info: " 
                    + "Name: " + name + ", " 
                    + "Type: " + (f.type || 'n/a') + ", "
                    + "Size: " + f.size + " bytes, " 
                    + "last modified: " + (f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : "n/a"));
            if (isSupported(f.type)) {
                var img = new Image();
                // eslint-disable-next-line no-loop-func
                img.onload = () => placeImageOnCanvas(img);
                img.crossOrigin = 'Anonymous';
                img.src = URL.createObjectURL(f);
            } else {
                publish('warning', f.name + ' is not a supported image type.');
            }
        }
    }

    const placeImageOnCanvas = (imageObj) => {
        let imgCanvas = imageCanvas.current;
        var context = imgCanvas.getContext('2d');
        context.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
        
        var imageAspectRatio = imageObj.width / imageObj.height;
        var canvasAspectRatio = imgCanvas.width / imgCanvas.height;
        var renderableHeight, renderableWidth, xStart, yStart;

        // If image's aspect ratio is less than canvas's we fit on height
        // and place the image centrally along width
        if(imageAspectRatio < canvasAspectRatio) {
            renderableHeight = imgCanvas.height;
            renderableWidth = imageObj.width * (renderableHeight / imageObj.height);
            xStart = (imgCanvas.width - renderableWidth) / 2;
            yStart = 0;
        }
        // If image's aspect ratio is greater than canvas's we fit on width
        // and place the image centrally along height
        else if(imageAspectRatio > canvasAspectRatio) {
            renderableWidth = imgCanvas.width
            renderableHeight = imageObj.height * (renderableWidth / imageObj.width);
            xStart = 0;
            yStart = (imgCanvas.height - renderableHeight) / 2;
        }

        // Happy path - keep aspect ratio
        else {
            renderableHeight = imgCanvas.height;
            renderableWidth = imgCanvas.width;
            xStart = 0;
            yStart = 0;
        }
        context.drawImage(imageObj, xStart, yStart, renderableWidth, renderableHeight);
        // add the image to the images array
        let image = imgCanvas.toDataURL("image/png", 1.0);
        onChange(image);
    }

    return (
        <>
            <input type='file' style={{display:'none'}} ref={fileInput} onChange={handleFileSelection} />
            <div style={{width:width+'px', height:height+'px',display:'none'}}>
                <canvas width={width} height={height} ref={imageCanvas}></canvas>
            </div>
            <div>
                <button className='linkButton' onClick={selectImage}>{buttonTitle}</button>
            </div>
        </>
    )
}

export default HiddenCanvas;
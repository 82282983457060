import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const EventsContext = React.createContext(null);

function EventsProvider({type = null, children}) {
    const { runAction } = useCore();
    const [loadingEvents, setLoadingEvents] = useState(true);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        loadEvents();
    }, [])

    const loadEvents = () => {
        setLoadingEvents(true);
        const data = (type ? {type} : {});
        runAction('get_all_events', data, (response) => {
            setEvents(response.events);
            setLoadingEvents(false);
        });
    }

    const createEvent = (data, callback) => {
        runAction('create_event', data, (response) => {
            callback();
            loadEvents();
        });
    }

    const updateEvent = (data, callback) => {
        runAction('update_event', data, response => {
            publish('success', 'Event saved.');
            callback();
            loadEvents();
        });
    }

    const provider = {
        loadingEvents,
        events,
        loadEvents,
        createEvent,
        updateEvent,
    }

    return <EventsContext.Provider value={provider}>{children}</EventsContext.Provider>
}

function useEvents() {
    const context = React.useContext(EventsContext);
    if (!context) {
        throw Error('useEvents must be used with an EventsProvider');
    }
    return context;
}

export { EventsProvider, useEvents }
import React, { useEffect, useState } from 'react';
import { formatCurrency } from '_base';
import moment from 'moment';
import Modal from 'components/modal';
import { useStripeIntents } from 'contexts/stripeIntents';
import CurrencyInput from 'react-currency-input-field';

function PaymentDetails({intent, onHide}) {
    const { refund } = useStripeIntents();
    const [reason, setReason] = useState('duplicate');
    const [refundAmount, setRefundAmount] = useState();
    const [showRefundModal, setShowRefundModal] = useState(false);

    useEffect(() => {
        console.log('intent:', intent);
    }, [intent])

    const refundHandler = () => {
        const data = {
            chargeId: intent.latest_charge,
            reason: reason,
            amount: refundAmount*100
        }
        refund(data, () => {
            setShowRefundModal(false);
            onHide();
        });
    }

    const showRefundModalHandler = () => {
        setRefundAmount(intent.amount/100);
        setShowRefundModal(true);
    }

    return (
        <div className='settings-panel'>
            <h4>Payment</h4>
            <hr/>
            <h5>Date</h5>
            <div>{moment(intent.created*1000).format('dddd, MMMM Do YYYY, h:mm:ss a')}</div>
            <h5>Amount</h5>
            <div>{`${formatCurrency(intent.amount/100)} (${intent.currency})`}</div>
            <h5>Status</h5>
            <div>{intent.status}</div>
            <h5>Description</h5>
            <div>{intent.description}</div>
            {intent.canceled_at &&
                <>
                    <h5>Canceled</h5>
                    <div>{moment(intent.canceled_at).format('dddd, MMMM Do YYYY, h:mm:ss a')}</div>
                    <h5>Reason</h5>
                    <div>{intent.cancellation_reason}</div>
                </>
            }
            {intent.status === 'succeeded' &&
                <button onClick={() => showRefundModalHandler()}>Refund</button>
            }
            <Modal 
                title='Refund payment' 
                showing={showRefundModal} 
                onHide={() => setShowRefundModal(false)}
                buttons={[
                    {
                        type: 'primary', 
                        handler: () => {refundHandler();},
                        label:'Refund'}
                ]}>
                <p>Refunds take 5-10 days to appear on a customer's statement. Stripe's fees<br/> 
                    for the original payment won't be returned, but there are no additional<br/> 
                    fees for the refund. .</p>
                <label>Reason (required)</label>
                <select value={reason} onChange={e => setReason(e.target.value)}>
                    <option value='duplicate'>Duplicate</option>
                    <option value='requested_by_customer'>Requested by customer</option>
                    <option value='fraudulent'>Fraudulent</option>
                </select>
                <label>Amount</label>
                <div>
                <CurrencyInput prefix='$' value={refundAmount} onValueChange={(value) => setRefundAmount(value)}/>
                </div>
            </Modal>
        </div>
    )
}

export default PaymentDetails;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const EventMapContext = React.createContext(null);

function EventMapProvider({eventId, eventMap, children}) {
    const [id, setId] = useState(null);
    const [label, setLabel] = useState('');
    const [backgroundImage, setBackgroundImage] = useState('');
    const [elements, setElements] = useState([]);
    const [selections, setSelections] = useState([]);
    const [available, setAvailable] = useState(false);
    const [availableStartdate, setAvailableStartdate] = useState(null);
    const [availableEnddate, setAvailableEnddate] = useState(null);
    const [notificationEmail, setNotificationEmail] = useState('');
    const [confirmationTemplate, setConfirmationTemplate] = useState(0);
    const [memberDiscount, setMemberDiscount] = useState(0.0);
    const { runAction } = useCore();

    useEffect(() => {
        if (eventMap) {
            setId(eventMap.id);
            setLabel(eventMap.label);
            setBackgroundImage(eventMap.backgroundImage);
            setElements(eventMap.elements);
            setAvailable(eventMap.available);
            setAvailableStartdate(eventMap.availableStartdate);
            setAvailableEnddate(eventMap.availableEnddate);
            setNotificationEmail(eventMap.notificationEmail);
            setConfirmationTemplate(eventMap.confirmationTemplate);
            setMemberDiscount(eventMap.memberDiscount);
        } else {
            setId(null);
            setLabel('');
            setBackgroundImage('');
            setElements([]);
            setAvailable(false);
            setAvailableStartdate(Date.now());
            setAvailableEnddate(Date.now());
            setNotificationEmail('');
            setConfirmationTemplate(0);
            setMemberDiscount(0.0);
        }
        setSelections([]);
    }, [eventMap])

    const saveEventMap = (callback) => {
        if (eventMap) {
            updateEventMap(callback);
        } else {
            createEventMap(callback);
        }
    }

    const createEventMap = async (callback) => {
        const data = {eventId, label, backgroundImage, available, availableStartdate, availableEnddate, notificationEmail, confirmationTemplate, memberDiscount, elements};
        await runAction('create_event_map', data, response => {
            callback(response.id);
        })
    }

    const updateEventMap = async (callback) => {
        const data = {eventId, id: eventMap.id, label, backgroundImage, available, availableStartdate, availableEnddate, notificationEmail, confirmationTemplate, memberDiscount, elements};
        await runAction('update_event_map', data, response => {
            callback();
        })
    }

    const deleteEventMap = async (callback) => {
        await runAction('delete_event_map', {id: eventMap.id}, response => {
            callback();
        })
    }

    const provider = {
        id,
        label, setLabel, 
        backgroundImage, setBackgroundImage, 
        elements, setElements,
        selections, setSelections,
        available, setAvailable,
        availableStartdate, setAvailableStartdate,
        availableEnddate, setAvailableEnddate,
        notificationEmail, setNotificationEmail,
        confirmationTemplate, setConfirmationTemplate,
        memberDiscount, setMemberDiscount,
        saveEventMap,
        deleteEventMap,
    }

    return <EventMapContext.Provider value={provider}>{children}</EventMapContext.Provider>
}

function useEventMap() {
    const context = React.useContext(EventMapContext);
    if (!context) {
        throw new Error('useEventMap must be used within an EventMapProvider');
    }
    return context;
}

export { EventMapProvider, useEventMap }
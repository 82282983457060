import { useEvent } from 'contexts/event';
import React, { useState } from 'react';
import Products from './products';
import AmazingTabs from 'components/amazingTabs';
import Rentals from './rentals';

const tabs = [
    {id: 'rentals', label: 'Equipment Rental'},
    {id: 'products', label: 'Products'},
]

function Sales() {
    const { event } = useEvent();
    const [view, setView] = useState('rentals');

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Equipment Rental : {event.name}</div>
                </div>
            </div>
            <p>Sales allows you to rent point of sale (POS) tablet devices with built in wireless LTE and wifi capabilities and
                chromebooks. You can make an equipment rental requesthere. In the products tab is where you would configure items 
                to sell at your car show through the rented point of sale tablet devices from Middletwin.</p>
            <p>Contact Middletwin at sales@middletwin.com to learn more and see a live demo of the equipment for your show before 
                configuring items to sell here. The items you configure here will only be visible in Middletwin rented point of
                sale devices. Some examples of items you can sell are: 
                <ul>
                    <li>Spectator admission</li>
                    <li>Day of show participant registration</li>
                    <li>Swapper/vendor spaces</li>
                    <li>Merchandise</li>
                </ul>
                And all sales will be directly deposited into your bank account like they are for all other sales, and you'll be
                able to see them and generate reports from them in the Finance view of your admin portal. The equipment
                will be provisioned and ready to go before it is delivered to you with instructions on how to use the equipment, 
                and we will provide support to you during your show.
            </p>
            <div style={{padding:'50px'}}>
                <AmazingTabs tabs={tabs} selected={view} onClick={newView => setView(newView.id)} />
                {view === 'rentals' && <Rentals />}
                {view === 'products' && <Products />}
            </div>
        </div>
    )
}

export default Sales;
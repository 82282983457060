import React, { useEffect, useState } from 'react';
import Loading from 'components/loading';
import { useMemberSettings } from 'contexts/memberSettings';
import Checkbox from 'components/checkbox';

function Preferences() {
    const { settingsLoading, settings, saveSettings } = useMemberSettings();
    const [autoRenew, setAutoRenew] = useState(true);
    const [notifyNewEvent, setNotifyNewEvent] = useState(false);
    const [notifyNewFile, setNotifyNewFile] = useState(false);
    const [notifyNewProduct, setNotifyNewProduct] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    const [enewsletter, setEnewsletter] = useState(true);
    const [roster, setRoster] = useState(true);
    const [moderator, setModerator] = useState(false);
    const [rosterPartner, setRosterPartner] = useState(true);
    const [rosterLocale, setRosterLocale] = useState(true);
    const [rosterEmail, setRosterEmail] = useState(true);
    const [rosterPhone, setRosterPhone] = useState(true);

    useEffect(() => {
        if (!settingsLoading && settings) {
            setAutoRenew(settings.autoRenew);
            setNotifyNewEvent(settings.notifyNewEvent);
            setNotifyNewFile(settings.notifyNewFile);
            setNotifyNewProduct(settings.notifyNewProduct);
            setNewsletter(settings.newsletter);
            setEnewsletter(settings.enewsletter);
            setRoster(settings.roster);
            setModerator(settings.moderator);
            setRosterPartner(settings.rosterPartner);
            setRosterLocale(settings.rosterLocale);
            setRosterEmail(settings.rosterEmail);
            setRosterPhone(settings.rosterPhone);
        }
    }, [settingsLoading])

    useEffect(() => {
        if (settings) {
            const data = {...settings, autoRenew, notifyNewEvent, notifyNewFile, notifyNewProduct, newsletter, 
                enewsletter, roster, moderator, rosterPartner, rosterLocale, rosterEmail, rosterPhone};
            // convert to json strings
            const dataStr = JSON.stringify(data);
            const settingsStr = JSON.stringify(settings);
            // compare
            if (dataStr !== settingsStr) {
                saveSettings(data);
            }
        }
    }, [autoRenew, notifyNewEvent, notifyNewFile, notifyNewProduct, newsletter, enewsletter, roster, moderator,
        rosterPartner, rosterLocale, rosterEmail, rosterPhone
    ])

    return (
        <div className='details-row'>
            {settingsLoading ? <Loading /> : 
                <>
                <div className='details-column'>
                    <label>Notifications</label>
                    <div><Checkbox checked={notifyNewEvent} onChange={() => setNotifyNewEvent(!notifyNewEvent)} /> Notify of new events</div>
                    <div><Checkbox checked={notifyNewFile} onChange={() => setNotifyNewFile(!notifyNewFile)} /> Notify of new files</div>
                    <div><Checkbox checked={notifyNewProduct} onChange={() => setNotifyNewProduct(!notifyNewProduct)} /> Notify of new products</div>
                </div>
                <div className='details-column'>
                    <label>Newsletter</label>
                    <div><Checkbox checked={newsletter} onChange={() => setNewsletter(!newsletter)} /> Mail newsletter</div>
                    <div><Checkbox checked={enewsletter} onChange={() => setEnewsletter(!enewsletter)} /> Email newsletter</div>
                </div>
                <div className='details-column'>
                    <label>Roster/Directory</label>
                    <div><Checkbox checked={roster} onChange={() => setRoster(!roster)} /> List in directory/Roster</div>
                    <div><Checkbox checked={rosterPartner} onChange={() => setRosterPartner(!rosterPartner)} /> Show Partner Name</div>
                    <div><Checkbox checked={rosterLocale} onChange={() => setRosterLocale(!rosterLocale)} /> Show City/State</div>
                    <div><Checkbox checked={rosterEmail} onChange={() => setRosterEmail(!rosterEmail)} /> Show Email</div>
                    <div><Checkbox checked={rosterPhone} onChange={() => setRosterPhone(!rosterPhone)} /> Show Phone</div>
                </div>
                <div className='details-column'>
                    <label>Other</label>
                    <div><Checkbox checked={autoRenew} onChange={() => setAutoRenew(!autoRenew)} /> Auto renew</div>
                    <div><Checkbox checked={moderator} onChange={() => setModerator(!moderator)} /> *Portal moderator</div>
                </div>
                </>
            }
        </div>
    )
}

export default Preferences;
import React from 'react';
import AmazingTable from 'components/amazingTable';
import moment from 'moment';
import { useNavigate } from 'react-router-dom/dist';
import SidePanel from 'components/sidePanel';
import NewEvent from './newEvent';
import { useEvents } from 'contexts/events';
import { TableSetupProvider } from 'contexts/tableSetup';

const eventColumns = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "name", label: "Name", display: true, datum: "name"},
    {id: 'type', label: 'Event Type', display: true, formatter: function(obj) {return obj.type === 0 ? 'Basic' : 'Competition Show'}},
    {id: 'availability', label: 'Availability', display: true, formatter: obj => obj.availability === 0 ? 'Public' : 'Members only'},
    {id: "startDate", label: "Start Date", display: true, formatter: function(obj) {return moment(new Date(obj.startDate)).format("YYYY-MM-DD");}},
    {id: "endDate", label: "End Date", display: true, formatter: function(obj) {return moment(new Date(obj.endDate)).format("YYYY-MM-DD");}},
    {id: "registration", label: "Registration", display: true, datum: "registration"},
    {id: "reg_startdate", label: "Registration Start", display: true, formatter: function(obj) {return moment(new Date(obj.reg_startdate)).format("YYYY-MM-DD");}},
    {id: "reg_enddate", label: "Registration End", display: true, formatter: function(obj) {return moment(new Date(obj.reg_enddate)).format("YYYY-MM-DD");}},
    {id: "reg_price", label: "Registration Price", display: true, datum: "reg_price"}
];

function Shows() {
    const { loadingEvents, events, loadEvents, createEvent } = useEvents();
    const newEventPanel = React.createRef();
    const navigate = useNavigate();

    const selectEvent = (event) => {
        navigate('/admin/events/show/' + event.id);
    }

    const saveNewEventHandler = (data) => {
        createEvent(data, () => newEventPanel.current.hide());
    }

    return(
        <>
            <TableSetupProvider tableName='events' defaultColumns={eventColumns}>
                <AmazingTable 
                    rows={events}
                    onRefresh={loadEvents}
                    onRowClick={selectEvent}
                    onCreate={() => newEventPanel.current.show()}
                    onCreateLabel='Add event'
                    loading={loadingEvents}
                />
            </TableSetupProvider>
            <SidePanel title='New Event' ref={newEventPanel}>
                <NewEvent onSave={saveNewEventHandler} />
            </SidePanel>
        </>
    )
}

export default Shows;
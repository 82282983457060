import React, { useRef, useState } from 'react';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import { formatPhoneNumber } from '_base';
import WorkerDetails from './WorkerDetails';
import { useWorkers } from 'contexts/workers';
import { ShiftsProvider } from 'contexts/shifts';
import { TableSetupProvider } from 'contexts/tableSetup';

const workerColumns = [
    {id: 'id', label: 'ID', display: false, datum: 'id'},
    {id: "name", label: "Name", display: true, formatter: function(obj) {return obj.fname + " " + obj.lname}},
    {id: "email", label: "Email", display: true, datum: "email"},
    {id: "phone", label: "Phone", display: true, formatter: function(obj) {return formatPhoneNumber(obj.phone);}},
    {id: "gift", label: "Gift", display: true, datum: "shirt"}
];

function Workers({event}) {
    const { workersLoading, workers, reload, deleteWorkers } = useWorkers();
    const [worker, setWorker] = useState(null);
    const detailsPanel = useRef(null);

    const selectWorker = (wrkr) => {
        setWorker(wrkr);
        detailsPanel.current.show();
    }

    const onSaveHandler = () => {
        detailsPanel.current.hide();
    }

    const handleCreateWorker = () => {
        setWorker(null);
        detailsPanel.current.show();
    }

    const handleDeleteWorkers = (wrkrs) => {
        const ids = wrkrs.map(w => w.id);
        deleteWorkers(ids);
    }

    const handlePrint = () => {
        var csvData = [["fname", "lname", "email", "phone", "gift", "notes"]];
        workers.forEach(w => csvData.push([w.fname, w.lname, w.email, w.phone, w.shirt, w.notes]));

        let csvContent = "data:text/csv;charset=utf-8," 
            + csvData.map(e => e.join(",")).join("\n");

        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }

    return (
        <div className='communication-area'>
            <TableSetupProvider tableName='worker' defaultColumns={workerColumns}>
                <AmazingTable 
                    rows={workers}
                    onExport={handlePrint}
                    onRefresh={reload}
                    onRowClick={selectWorker}
                    onCreate={handleCreateWorker}
                    onCreateLabel='Add worker'
                    onDelete={handleDeleteWorkers}
                    loading={workersLoading} />
            </TableSetupProvider>
            <SidePanel title='Worker' ref={detailsPanel}>
                <ShiftsProvider eventId={event.id}>
                    <WorkerDetails worker={worker} onSave={onSaveHandler} event={event} />
                </ShiftsProvider>
            </SidePanel>
        </div>
    )
}

export default Workers;
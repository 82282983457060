import AmazingTable from 'components/amazingTable';
import { EventMapProvider } from 'contexts/eventMap';
import { useEventMaps } from 'contexts/eventMaps';
import { TableSetupProvider } from 'contexts/tableSetup';
import React, { useState } from  'react';
import MapDetails from './mapDetails';
import { ReservationsProvider } from 'contexts/reservations';
import { VendorsProvider } from 'contexts/vendors';
import DetailsPanel from 'components/detailsPanel';

const columns = [
    {id: 'id', label:'ID', display: false, datum: 'id'},
    {id: 'label', label:'Label', display: true, datum: 'label'},
    {id: 'available', label: 'Available Online', display: true, formatter: obj => obj.available ? 'Yes' : 'No'},
    {id: 'elements', label:'Spaces', display: true, formatter: obj => obj.elements.length},
    {id: 'reservations', label:'Reservations', display: true, formatter: obj => obj.reservations.length}
];

function MapManager() {
    const { eventId, mapsLoading, maps, loadEventMaps } = useEventMaps();
    const [eventMap, setEventMap] = useState(null);
    const [showEventMap, setShowEventMap] = useState(false);

    const showMap = (map) => {
        setEventMap(map);
        setShowEventMap(true);
    }

    const createMap = () => {
        setEventMap(null);
        setShowEventMap(true);
    }

    const handleMapDone = () => {
        setShowEventMap(false);
        loadEventMaps();
    }

    const removeMaps = () => {}

    return (
        <div>
            <TableSetupProvider tableName='maps' defaultColumns={columns}>
                <AmazingTable 
                    rows={maps}
                    onRefresh={loadEventMaps}
                    onRowClick={showMap}
                    onCreate={createMap}
                    onCreateLabel='Add map'
                    onDelete={removeMaps}
                    loading={mapsLoading} />
            </TableSetupProvider>
            <DetailsPanel title={eventMap ? eventMap.label : 'New map'} showing={showEventMap} onHide={handleMapDone}>
                {showEventMap &&
                    <EventMapProvider eventId={eventId} eventMap={eventMap}>
                        <VendorsProvider eventId={eventId}>
                            <ReservationsProvider eventId={eventId} eventMapId={eventMap?.id}>
                                <MapDetails onDone={handleMapDone}/>
                            </ReservationsProvider>
                        </VendorsProvider>
                    </EventMapProvider>
                }
            </DetailsPanel>
        </div>
    )
}

export default MapManager;
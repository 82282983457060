import { useEquipmentRentals } from 'contexts/equipmentRental';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

function RentalDetails({rental, onSave}) {
    const { createRental, updateRental } = useEquipmentRentals();
    const [responsiblePerson, setResponsiblePerson] = useState('');
    const [responsiblePersonEmail, setResponsiblePersonEmail] = useState('');
    const [responsiblePersonPhone, setResponsiblePersonPhone] = useState('');
    const [equipmentType, setEquipmentType] = useState(1);
    const [quantity, setQuantity] = useState(1);
    const [startDate, setStartDate] = useState(moment().add(1, 'w').valueOf());
    const [endDate, setEndDate] = useState(moment().add(1, 'w').add(3, 'd').valueOf());
    const [status, setStatus] = useState(1);
    const [paid, setPaid] = useState(false);

    useEffect(() => {
        if (rental) {
            setResponsiblePerson(rental.responsiblePerson);
            setResponsiblePersonEmail(rental.responsiblePersonEmail);
            setResponsiblePersonPhone(rental.responsiblePersonPhone);
            setEquipmentType(rental.equipmentType);
            setQuantity(rental.quantity);
            setStartDate(rental.rentalStartDate);
            setEndDate(rental.rentalEndDate);
            setStatus(rental.status);
            setPaid(rental.prepaid);
        } else {
            setResponsiblePerson('');
            setResponsiblePersonEmail('');
            setResponsiblePersonPhone('');
            setEquipmentType(1);
            setQuantity(1);
            setStartDate(moment().add(1, 'w').valueOf());
            setEndDate(moment().add(1, 'w').add(3, 'd').valueOf());
            setStatus(1);
            setPaid(false);
        }
    }, [rental])

    const save = () => {
        if (rental) {
            const data = {...rental, equipmentType, quantity, rentalStartDate: startDate, rentalEndDate: endDate, status, prepaid: paid,
                responsiblePerson, responsiblePersonEmail, responsiblePersonPhone};
            updateRental(data);
        } else {
            const data = {
                equipmentType,
                quantity, 
                rentalStartDate: startDate,
                rentalEndDate: endDate,
                status,
                prepaid: paid,
                responsiblePerson, 
                responsiblePersonEmail, 
                responsiblePersonPhone
            }
            createRental(data);
        }
        onSave();
    }

    return (
        <div>
            <div className='contact-preview'>
                <label>Person responsible for equipment</label>
                <input type='text' value={responsiblePerson} onChange={evt => setResponsiblePerson(evt.target.value)} maxLength={64} />
                <label>Person email address</label>
                <input type='text' value={responsiblePersonEmail} onChange={evt => setResponsiblePersonEmail(evt.target.value)} maxLength={128} />
                <label>Person phone number</label>
                <input type='text' value={responsiblePersonPhone} onChange={evt => setResponsiblePersonPhone(evt.target.value)} maxLength={64} />
                <label>Devices</label>
                <select value={equipmentType} onChange={evt => setEquipmentType(evt.target.value)}>
                    <option value='1'>Point of Sale Tablet ($25/day)</option>
                    <option value='2'>Data Entry Chromebook ($25/day)</option>
                </select>
                <label>How many devices</label>
                <input type='number' value={quantity} onChange={(e) => setQuantity(e.target.value)} min={1} max={100} step={1} />
                <label>Rental Start Date</label>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(Date.parse(date))}/>
                <label>Rental End Date</label>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(Date.parse(date))}/>
                <label>Status</label>
                <select value={status} onChange={evt => setStatus(evt.target.value)}>
                    <option value='1'>Submitted</option>
                    <option value='2'>In Review</option>
                    <option value='3'>Reserved</option>
                    <option value='4'>Unavailable</option>
                    <option value='5'>Shipped</option>
                    <option value='6'>Received</option>
                    <option value='99'>Unknown</option>
                </select>
                <label>Paid</label>
                <div><input type='checkbox' checked={paid} onChange={evt => setPaid(evt.target.checked)} /></div>
            </div>
            <div className='buttonsHolder'>
                <button onClick={save}>Save</button>
            </div>
        </div>
    )
}

export default RentalDetails;
import { useEquipmentRentals } from 'contexts/equipmentRental';
import { TableSetupProvider } from 'contexts/tableSetup';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import RentalDetails from './rentalDetails';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';

function Rentals() {
    const { loadingRentals, loadRentals, rentals, deleteRentals } = useEquipmentRentals();
    const [rental, setRental] = useState(null);
    const rentalPanel = useRef(null);

    const columns = [
            {id: 'id', label:'ID', display: false, datum: 'id'},
            {id: 'equipment', label:'Devices', display: true, style:{width:'100px'}, formatter: obj => obj.equipmentType === 1 ? 'Tablet' : 'Chromebook'},
            {id: 'quantity', label:'Quantity', display: true, style:{width:'50px'}, datum: 'quantity'},
            {id: 'start', label: 'Start Date', display: true, style:{width:'100px'}, formatter: obj => moment(obj.rentalStartDate).format('MMM D, YYYY')},
            {id: 'end', label:'End Date', display: true, style:{width:'100px'}, formatter: obj => moment(obj.rentalEndDate).format('MMM D, YYYY')},
            {id: 'created', label:'Created', display: true, style:{width:'100px'}, formatter: obj => moment(obj.created).format('MMM D, YYYY')},
            {id: 'modified', label:'Last Update', display: true, style:{width:'100px'}, formatter: obj => moment(obj.updated).format('MMM D, YYYY')},
            {id: 'status', label:'Status', display: true, style:{width:'100px'}, formatter: obj => getStatus(obj.status)},
            {id: 'paid', label:'Paid', display: true, style:{width:'100px'}, formatter: obj => obj.prepaid ? 'Yes' : 'No'},
            {id: 'person', label: 'Responsible Person', display: true, style:{width:'100px'}, datum: 'responsiblePerson'},
            {id: 'personEmail', label: 'Person Email', display: true, style:{width:'100px'}, datum: 'responsiblePersonEmail'},
            {id: 'personPhone', label: 'Person Phone', display: true, style:{width:'100px'}, datum: 'responsiblePersonPhone'},
        ];

    const getStatus = (id) => {
        let stat = 'Unknown';
        switch(id) {
            case 1: stat='Submitted'; break;
            case 2: stat='In review'; break;
            case 3: stat ='Reserved'; break;
            case 4: stat='Unavailable'; break;
            case 5: stat='Shipped'; break;
            case 6: stat='Received'; break;
            default: stat='Unknown'; break;
        }
        return stat;
    }
 
    const selectRental = (row) => {
        setRental(row);
        rentalPanel.current.show();
    }

    const handleCreate = () => {
        setRental(null);
        rentalPanel.current.show();
    }

    const handleDelete = (rows) => {
        const ids = rows.map(r => r.id);
        deleteRentals(ids);
    }
    const saveHandler = () => {
        rentalPanel.current.hide();
        setRental(null);
    }
    const handleHideRental = () => {
        setRental(null);
    }

    return (
        <>
            <TableSetupProvider tableName='rentals' defaultColumns={columns}>
                <AmazingTable 
                    rows={rentals}
                    onRefresh={loadRentals}
                    loading={loadingRentals}
                    onRowClick={selectRental}
                    onCreate={handleCreate}
                    onCreateLabel='Add rental'
                    onDelete={handleDelete} />
            </TableSetupProvider>
            <SidePanel title='Equipment Rental' ref={rentalPanel} onHide={handleHideRental}>
                <RentalDetails rental={rental} onSave={saveHandler} />
            </SidePanel>
        </>
    )
}

export default Rentals;
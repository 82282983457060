import React, { useEffect, useRef, useState } from 'react';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import { useMakesAndModels } from 'contexts/makesAndModels';
import { TableSetupProvider } from 'contexts/tableSetup';
import Toggle from 'components/toggle';
import { useEvent } from 'contexts/event';

const makeColumns = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "label", label: "Label", display: true, datum: "label"},
];

const modelColumns = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "label", label: "Label", display: true, datum: "label"},
];

function MakesAndModels() {
    const { makes, models, getMakes, getModels, saveMake, saveModel, deleteMakes, deleteModels } = useMakesAndModels();
    const { event, updateEventSettings } = useEvent(); 
    const [make, setMake] = useState(null);
    const [model, setModel] = useState(null);
    const [typedMakeAndModel, setTypedMakeAndModel] = useState(event.typedMakeAndModel);
    const makePanel = useRef(null);
    const modelPanel = useRef(null);
    
    const updateTypedMakesAndModelsHandler = (newVal) => {
        setTypedMakeAndModel(newVal);
        updateEventSettings({eventId: event.id, typedMakeAndModel: newVal})
    }

    const createMake = () => {
        setMake(null);
        makePanel.current.show();
    }

    const editMake = (selectedMake) => {
        setMake(selectedMake[0]);
        makePanel.current.show();
    }

    useEffect(() => {
        if (make) {
            console.log('make ', make);
            getModels(make.id);
        }
    }, [make])

    const getModelsHandler = () => {
        if (make) {
            getModels(make.id);
        }
    }

    const createModel = () => {
        setModel(null);
        modelPanel.current.show();
    }

    const editModel = (m) => {
        setModel(m);
        modelPanel.current.show();
    }

    const saveMakeHandler = (label) => {
        let data = {eventId: event.id, label: label};
        if (make) {
            data.id = make.id;
        }
        saveMake(data, () => {
            setMake(null);
            makePanel.current.hide();
        });
    }

    const saveModelHandler = (label) => {
        let data = {makeId: make.id, label: label}
        if (model) {
            data.id = model.id;
        }
        saveModel(data, () => {
            setModel(null);
            modelPanel.current.hide();
        });
    }

    const deleteMakesHandler = (rows) => {
        const ids = rows.map(make => make.id);
        deleteMakes(ids);
        setMake(null);
        setModel(null);
    }

    const deleteModelsHandler = (rows) => {
        const ids = rows.map(model => model.id);
        deleteModels(ids);
        setModel(null);
    }

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Makes and Models</div>
                </div>
            </div>
            <div style={{marginLeft:'50px', display: 'flex'}}>
                <label>Allow users to type make and model</label>
                <Toggle checked={typedMakeAndModel} onChange={newVal => updateTypedMakesAndModelsHandler(newVal)}/>
            </div>
            {!typedMakeAndModel && 
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={{width:'95%'}}>
                    <h3>Makes</h3>
                    <TableSetupProvider tableName='makes' defaultColumns={makeColumns}>
                        <AmazingTable
                            rows={makes}
                            onRefresh={getMakes}
                            onRowClick={(m) => setMake(m)}
                            onCreate={createMake}
                            onCreateLabel='Add make'
                            onEdit={editMake}
                            onDelete={deleteMakesHandler} />
                    </TableSetupProvider>
                    <SidePanel title='Make' ref={makePanel}>
                        <MakeDetails make={make} onSave={saveMakeHandler} />
                    </SidePanel>
                </div>
                <div style={{width:'95%'}}>
                    <h3>Models</h3>
                    <TableSetupProvider tableName='models' defaultColumns={modelColumns}>
                        <AmazingTable
                            rows={models}
                            onRefresh={getModelsHandler}
                            onRowClick={editModel}
                            onCreate={createModel}
                            onCreateLabel='Add model'
                            createDisabled={make === null}
                            onDelete={deleteModelsHandler} />
                    </TableSetupProvider>
                    <SidePanel title='Model' ref={modelPanel}>
                        <ModelDetails model={model} onSave={saveModelHandler} />
                    </SidePanel>
                </div>
            </div>
            }
        </div>
    )
}

export default MakesAndModels;

function MakeDetails({make, onSave}) {
    const [label, setLabel] = useState('');

    useEffect(() => {
        if (make) {
            setLabel(make.label);
        } else {
            setLabel('');
        }
    }, [make])

    const save = () => {
        onSave(label);
    }

    return (
        <div>
            <div className='contact-preview'>
                <label>Label</label>
                <input type='text' value={label} onChange={e => setLabel(e.target.value)} maxLength='64' />
            </div>
            <div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
        </div>
    )
}

function ModelDetails({model, onSave}) {
    const [label, setLabel] = useState('');

    useEffect(() => {
        if (model) {
            setLabel(model.label);
        } else {
            setLabel('');
        }
    }, [model])

    const save = () => {
        onSave(label);
    }

    return (
        <div>
            <div className='contact-preview'>
                <label>Label</label>
                <input type='text' value={label} onChange={e => setLabel(e.target.value)} maxLength='64' />
            </div>
            <div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
        </div>
    )
}
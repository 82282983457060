import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import 'style/modal.scss';
import WaitButton from './waitButton';

const showStyle = {dsiplay:'flex', transition: 'width 0.5s'};
const hideStyle = {display:'none', transition: 'width 0.5s'};

function Modal({showing, title, children, onHide, buttons=[]}) {

    const closeHandler = () => {
        if (onHide) {
            onHide();
        }
    }

    return (
        <div className='modalBackground' style={showing ? showStyle : hideStyle}>
            <div className='modalContainerOuter'>
                <div className='modalContainerInner'>
                    <div className='modalCloser'>
                        <FontAwesomeIcon icon={faTimes} onClick={closeHandler}/>
                    </div>
                    <div className='modalContainerTitle'>
                        <h4>{title}</h4>
                    </div>
                    <div className='modalContent'>
                        {children}
                    </div>
                    <div className='modalButtons'>
                        <div className='buttonsHolder' style={{justifyContent:'flex-start'}}>
                            {buttons.length == 0 ? 
                            <button className='secondary' onClick={closeHandler}>Cancel</button>
                            : buttons.map((button, i) => {
                                if (button.type === 'wait') {
                                    return <WaitButton waitMode={button.waitMode} onClick={button.handler} label={button.label} style={{padding: '2px 0'}}/>
                                }
                                return <button key={i} className={button.type} onClick={button.handler} disabled={button?.disabled}>{button.label}</button>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;
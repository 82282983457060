import React, { useEffect, useState } from 'react';
import Toggle from 'components/toggle';
import { formatFrequency } from '.';

function ServiceDetails(props) {
    const [id, setId] = useState(null);
    const [type, setType] = useState(undefined);
    const [enabled, setEnabled] = useState(true);
    const [frequency, setFrequency] = useState(0);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        if(props.service) {
            setId(props.service.id);
            setType(props.service.type);
            setEnabled(props.service.enabled);
            setFrequency(props.service.frequency);
        }
        if (props.types) {
            setTypes(props.types);
            if (!props.service && props.types.length > 0) {
                setType(props.types[0].id);
            }
        }
    }, [props.service, props.types]);

    useEffect(() => {
        if (type && types.length > 0) {
            const foundType = types.find(t => t.id === parseInt(type));
            if (foundType) {
                setFrequency(foundType.frequency);
            }
        }
    }, [type, types])

    const setFrequencyHandler = (e) => {
        let val = e.target.value;
        if (!isNaN(val)) {
            setFrequency(parseInt(val, 10));
        }
    }

    const save = () => {
        let svc = {
            type, enabled, frequency
        }
        if (id !== null) {
            svc.id = id;
        }
        props.onSave(svc)
    }

    return (
        <div className='contact-preview'>
            <p>You can only edit a service&amp;s frequency and it&amp;s enabled status.</p>
            <label>Service Type</label>
            <select value={type} onChange={e => setType(e.target.value)} disabled={id !== null}>
                {types.map((type) => <option key={type.id} value={type.id}>{type.label} {` ---- Runs every ${formatFrequency(type.frequency)}`}</option>)}
            </select>
            <label>Enabled</label>
            <Toggle checked={enabled} onChange={checked => setEnabled(checked)}/>
            <label>Frequency (milliseconds)</label>
            <input type='text' value={frequency} onChange={setFrequencyHandler} />
            <div className='button-container'>
                <button onClick={save}>Save</button>
            </div>
        </div>
    )
}

export default ServiceDetails;

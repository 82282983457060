import { useEventProduct } from 'contexts/eventProduct';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

function ProductDetails({product, onSave}) {
    const { createProduct, updateProduct } = useEventProduct();
    const [name, setName] = useState('');
    const [price, setPrice] = useState(0);
    const [category, setCategory] = useState(1);

    useEffect(() => {
        if (product) {
            setName(product.name);
            setPrice(product.price);
            setCategory(product.category);
        } else {
            setName('');
            setPrice(0);
            setCategory(1);
        }
    }, [product])

    const save = () => {
        const data = {
            name,
            price,
            category,
        }
        if (product) {
            updateProduct({...data, id: product.id});
        } else {
            createProduct(data);
        }
        onSave();
    }

    return (
        <div>
			<div className='contact-preview'>
				<label>Name</label>
				<input type='text' value={name} onChange={(evt) => setName(evt.target.value)} maxLength='64' />
                <label>Price</label>
                <CurrencyInput prefix='$' value={price} onValueChange={(value) => setPrice(value)} />
                <label>Category</label>
                <select value={category} onChange={evt => setCategory(evt.target.value)}>
                    <option value='1'>Participant Registration</option>
                    <option value='2'>Swap Meet registration</option>
                    <option value='3'>Vendor Registration</option>
                    <option value='4'>Merchandise</option>
                    <option value='5'>Spectator</option>
                    <option value='6'>Camping</option>
                    <option value='7'>Membership</option>
                    <option value='99'>Other</option>
                </select>
            </div>
            <div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
        </div>
    )
}

export default ProductDetails;

/*
ParticipantRegistration(1, "Participant Registration"),
	    SwapRegistration(2, "Swap Meet Registration"),
	    VendorRegistration(3, "Vendor Registration"),
	    Merchandise(4, "Merchandise"),
	    Spectator(5, "Spectator"),
	    Camping(6, "Camping"),
	    Membership(7, "Membership"),
	    Other(99, "Other");*/
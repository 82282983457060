import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import { useEmailTemplates } from 'contexts/emailTemplates';
import EventTicketOptions from './eventTicketOptions';

function EventTicket({eventTicket, onSave}) {
    const { templates } = useEmailTemplates();
    const [description, setDescription] = useState(eventTicket ? eventTicket.description : 'My event ticket');
    const [info, setInfo] = useState(eventTicket ? eventTicket.info : '');
    const [startDate, setStartDate] = useState(eventTicket ? moment(eventTicket.startdate).toDate() : moment().toDate());
    const [endDate, setEndDate] = useState(eventTicket ? moment(eventTicket.enddate).toDate() : moment().add(1, 'months').toDate());
    const [quantity, setQuantity] = useState(eventTicket ? eventTicket.quantity : 100);
    const [limit, setLimit] = useState(eventTicket ? eventTicket.limit : 10);
    const [price, setPrice] = useState(eventTicket ? eventTicket.price : 10);
    const [emailTemplateId, setEmailTemplateId] = useState(eventTicket ? eventTicket.emailTemplateId : 0);
    const [availability, setAvailability] = useState(eventTicket ? eventTicket.availability : 0);
    const [options, setOptions] = useState(eventTicket ? eventTicket.options : []);

    const save = () => {
        let data = {
            description,
            info,
            startdate: startDate.getTime(),
            enddate: endDate.getTime(),
            quantity,
            limit,
            price,
            emailTemplateId,
            availability,
            options
        };
        if (onSave) {
            onSave(data);
        }
    }

    const optionsChangeHandler = (options) => {
        setOptions(options);
    }

    return (
        <div>
			<div className='contact-preview'>
				<label>Description</label>
				<input type='text' value={description} onChange={(evt) => setDescription(evt.target.value)} maxLength='64' />
                <label>Info (1024 characters max)</label>
                <textarea
                    defaultValue={info} 
                    onChange={(evt) => setInfo(evt.target.value)}
                    style={{height:'100px'}} 
                    maxLength='1024'></textarea>
				<label>Sale start date</label>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}/>
				<label>Sale end date</label>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)}/>
				<label>Total ticket limit</label>
				<CurrencyInput allowDecimals={false} allowNegativeValue={false} value={quantity} onValueChange={(value) => setQuantity(value)} />
                <label>Single purchase limit</label>
                <input type='number' value={limit} onChange={evt => setLimit(evt.target.value)} min={1} max={10} step={1} />
                <label>Price</label>
                <CurrencyInput prefix='$' value={price} onValueChange={(value) => setPrice(value)} />
                <label>Confirmation Email Template</label>
                <select value={emailTemplateId} onChange={e => setEmailTemplateId(e.target.value)}>
                    <option value="0">None</option>
                    {templates.map(et => <option key={et.id} value={et.id}>{et.name}</option>)}
                </select>
                <label>Availability</label>
                <select value={availability} onChange={e => setAvailability(e.target.value)}>
                    <option value='0'>Public (Anyone can purchase)</option>
                    <option value='1'>Event participants and vendors only</option>
                </select>
                <EventTicketOptions options={options} onChange={optionsChangeHandler} />
			</div>
			<div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
		</div>
    )
}


export default EventTicket;
import AmazingTabs from "components/amazingTabs";
import { VotersProvider } from "contexts/voter";
import React from "react";
import { useState } from "react";
import MobileVotingSetup from "./mobileVotingSetup";
import MobileVotingBallots from "./mobileVotingBallots";
import { useEvent } from "contexts/event";

const tabs = [
    {id: 'ballots', label: 'Ballots'},
    {id: 'setup', label: 'Setup'},
]

function MobileVoting() {
    const { event } = useEvent();
    const [view, setView] = useState('ballots');

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Mobile Ballots</div>
                </div>
            </div>
            <AmazingTabs tabs={tabs} selected={view} 
                onClick={newView => setView(newView.id)}></AmazingTabs>
            {view === 'ballots' &&
                <VotersProvider eventId={event.id} all={true}>
                    <MobileVotingBallots eventId={event.id} />
                </VotersProvider>
            }
            {view === 'setup' &&
                <MobileVotingSetup />
            }
        </div>
    )
}

export default MobileVoting;
import AmazingTable from 'components/amazingTable';
import { TableSetupProvider } from 'contexts/tableSetup';
import { useTransfers } from 'contexts/transfers';
import React from 'react';
import moment from 'moment';
import { formatCurrency } from '_base';

const transferColumns = [
    {id: 'id', label: 'ID', display: false, datum: 'id'},
    {id: 'transfercreated', label: 'Created', display: true, style:{width: '100px'}, formatter: obj => moment(obj.created*1000).format('YYYY-MM-DD')},
    {id: 'amount', label: 'Amount', display: true, style:{width: '100px'}, formatter: obj => formatCurrency(obj.source_transaction.amount/100)},
    {id: 'fee', label: 'Fee', display: true, style:{width: '100px'}, formatter: obj => formatCurrency(obj.source_transaction.application_fee_amount/100)},
    {id: 'total', label: 'Total', display: true, style:{width: '100px'}, formatter: obj => formatCurrency((obj.source_transaction.amount-obj.source_transaction.application_fee_amount)/100)},
    {id: 'description', label: 'Description', display: true, formatter: obj => obj.source_transaction.description},
]

function Transfers() {
    const { loadingTransfers, transfers } = useTransfers();

    return (
        <TableSetupProvider tableName='transfers' defaultColumns={transferColumns}>
            <AmazingTable 
                loading={loadingTransfers}
                rows={transfers}
            />
        </TableSetupProvider>
    )
}

export default Transfers
import React, { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import 'style/MemberCards.scss';
import { useCore } from 'contexts/core';
import moment from 'moment';

export async function loader({params}) {
    return params;
}

function MemberCards() {
    const { runAction } = useCore();
    const loaderData = useLoaderData();
    const [members, setMembers] = useState([]);
    const [portalSettings, setPortalSettings] = useState(null);

    useEffect(() => {
        if (loaderData.tenant) {
            runAction('get_tenant', {tenantUUID: loaderData.tenant}, (response) => {
                setPortalSettings(response.portalSettings);
            })
        }
    }, [loaderData.tenant])

    useEffect(() => {
        if (loaderData.tenant) {
            let data = {
                tenantUUID: loaderData.tenant,
                type: loaderData.type
            };
            runAction('get_all_members', data, response => {
                setMembers(response.members)
            });
        }
    }, [loaderData.tenant, loaderData.type])

    return (
        <div className='printable-area portrait'>
            <div className='memberCardsOuter'>
                <div className='memberCardsInner'>
                    {members.map((member, idx) => <MemberCard settings={portalSettings} key={idx} member={member} />)}
                </div>
            </div>
        </div>
    )
}

function MemberCard({settings, member}) {

    const getLogo = () => {
        if (settings && settings.logo && settings.logo.length > 0) {
            return settings.logo;
        }
        return '';
    }

    return (
        <div className='memberCardOuter'>
            <div className='memberCardInner'>
                <div className='logoContainer'>
                    <img className='clubLogo' src={getLogo()} alt='Club Logo'/>
                </div>
                <div className='content'>
                    {`${member.fname} ${member.lname}`}<br />
                    {`Member #: ${member.id}`}<br />
                </div>

                {member.join_date && 
                    <div className='footer'>Member Since: {moment(member.join_date).format('YYYY')}</div>
                }
            </div>
        </div>
    )
}

export default MemberCards;
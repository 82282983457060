import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const ReservationContext = React.createContext(null);

function ReservationsProvider({eventId, eventMapId, children}) {
    const { runAction } = useCore();
    const [reservationsLoading, setReservationsLoading] = useState(false);
    const [reservations, setReservations] = useState([]);

    useEffect(() => {
        if (eventMapId) {
            getReservations();
        }
    }, [eventMapId])

    const getReservations = async () => {
        setReservationsLoading(true);
        await runAction('get_all_reservations', {eventId, eventMapId}, (response) => {
            setReservations(response.reservations);
            setReservationsLoading(false);
        })
    }

    const createReservation = async (vendorId, elementId) => {
        const data = {eventId, eventMapId, vendorId, eventMapElements: [elementId]}
        await runAction('create_reservation', data, () => {
            getReservations();
        })
    }

    const deleteReservation = async (reservationId) => {
        await runAction('delete_reservation', {id: reservationId}, (response) => {
            if (response.success) {
                getReservations();
            }
        })
    }

    const provider = {
        reservationsLoading,
        getReservations,
        reservations,
        createReservation,
        deleteReservation,
    }

    return <ReservationContext.Provider value={provider}>{children}</ReservationContext.Provider>
}

function useReservations() {
    const context = React.useContext(ReservationContext);
    if (!context) {
        throw new Error('useReservations must be used within a ReservationsProvider');
    }
    return context;
}

export { ReservationsProvider, useReservations }
import React, {useEffect, useState} from 'react';
import Loading from 'components/loading';
import Core from 'util/core';
import ResultsTable from './resultsTable';
import { useLoaderData } from 'react-router-dom';

export async function loader({params}) {
    return params;
}

function Results() {
    const loaderData = useLoaderData();
    const [entries, setEntries] = useState([]);
    const [pcentries, setPcentries] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getResults = () => {
        let data = {event: loaderData.event}
        Core.runAction('get_votes', data, (response) => {
            setEntries(response.entries);
            setPcentries(response.pcentries);
            setLoading(false);
        })
    }

    return (
        <div className='printable-area landscape'>
            <div className='results-table-outer'>
                {loading ? <Loading /> : <ResultsTable entries={entries} />}
                <div className='pcentries'>
                Peoples Choice (2D bar code) Results
                <ul>
                    {pcentries.map(ntry => <li>{`${ntry.car_number} : ${ntry.fname} ${ntry.lname} ${ntry.votes} vote(s)`}</li>)}
                </ul>
            </div>
            </div>
        </div>
    )
}

export default Results;
import React, { useState } from 'react';
import { SchedulesProvider } from 'contexts/schedules';
import { WorkersProvider } from 'contexts/workers';
import AmazingTabs from 'components/amazingTabs';

import Schedules from './Schedules';
import Workers from './Workers';
import { ShiftsProvider } from 'contexts/shifts';

const tabs = [
    {id: 'schedules', label: 'Schedules'}, {id: 'workers', label: 'Workers'}
]

function Details({event}) {
    const [tab, setTab] = useState('schedules');
    
    const tabHandler = (tab) => {
        setTab(tab.id);
    }
     
    return(
        <div style={{padding:'50px'}}>
            <WorkersProvider eventId={event.id}>
                <SchedulesProvider eventId={event.id}>
                    <AmazingTabs tabs={tabs} selected={tab} onClick={tabHandler} />
                    {tab === 'schedules' && 
                        <ShiftsProvider eventId={event.id}>
                            <Schedules eventId={event.id} />
                        </ShiftsProvider>
                    }
                    {tab === 'workers' && 
                        <Workers event={event} />
                    }
                </SchedulesProvider>
            </WorkersProvider>
        </div>
    )
}

export default Details;
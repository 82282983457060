import { useToken } from 'contexts/token';
import { VotersProvider, useVoters } from 'contexts/voter';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import QRCode from 'react-qr-code';
import './voterBallots.scss'
import { EventProvider, useEvent } from 'contexts/event';
import moment from 'moment';

export async function loader({params}) {
    return params;
}

function VoterBallots() {
    const loaderData = useLoaderData();
    const { tokenLoading } = useToken();

    return (
        <>
            {!tokenLoading && loaderData.eventid &&
                <EventProvider eventId={loaderData.eventid}>
                    <VotersProvider eventId={loaderData.eventid} batch={loaderData.batch}>
                        <Ballots />
                    </VotersProvider>
                </EventProvider>
            }
        </>
    )
}

function Ballots() {
    const { votersLoading, voters } = useVoters();
    const { eventLoading } = useEvent();

    return (
        <div className='ballotsOuter printable-area portrait'>
            {!votersLoading && !eventLoading &&
                <div className='ballotsInner'>
                    {voters.map(v => <div key={v.id}><Ballot voter={v} /></div>)}
                </div>
            }
        </div>
    )
}

function Ballot({voter}) {
    const { event } = useEvent();
    const loaderData = useLoaderData();

    return (
        <div className='ballot'>
            <div className='ballotDetails'>
                <h1>{event.name}</h1>
                <h5>Voting Ballot</h5>
                <div className='instructions'>
                    <p>
                        Voting begins {moment(event.votingStart).format('dddd, MMMM Do YYYY, h:mm a')}<br/>
                        Voting ends {moment(event.votingEnd).format('dddd, MMMM Do YYYY, h:mm a')}<br/>
                        ({voter.uuid})
                    </p>
                    <p>
                        Use the camera on your iPhone or Android to scan the 2 dimensional bar code below to load the
                        ballot into your phones web browser. Complete and submit the ballot to have your vote counted.
                        Ballots can only be used once.
                    </p>
                </div>
                <QRCode size={100} value={`https://${loaderData.subdomain}.middletwin.com/portal/voterballot/${voter.uuid}/${voter.eventId}`}/>
                <div className='instructions'>
                    <p>{event.votingInstructions}</p>
                </div>
                </div>
            <div className='ballotNotes'>
                
            </div>
        </div>
    )
}

export default VoterBallots;
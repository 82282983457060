import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const StripeAccountContext = React.createContext(null);

function StripeAccountProvider({children}) {
    const { runAction } = useCore();
    const [loadingAccounts, setLoadingAccounts] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        loadAccounts();
    }, [])

    const loadAccounts = async() => {
        setAccounts([]);
        setLoadingAccounts(true);
        await runAction('get_stripe_accounts', {}, response => {
            const stripeAccounts = response.accounts;
            setAccounts(stripeAccounts.map((piJson) => JSON.parse(piJson)));
            setHasMore(response.hasMore);
            setLoadingAccounts(false);
        })
    }

    const deleteAccount = async (accountId) => {
        await runAction('delete_stripe_account', {accountId}, response => {
            loadAccounts();
        })
    }

    const provider = {
        loadingAccounts,
        loadAccounts,
        accounts,
        hasMore,
        deleteAccount,
    }

    return <StripeAccountContext.Provider value={provider}>{children}</StripeAccountContext.Provider>
}

function useStripeAccounts() {
    const context = React.useContext(StripeAccountContext);
    if (!context) {
        throw new Error('useStripeAccount must be used within a StripeAccountProvider');
    }
    return context;
}

export { StripeAccountProvider, useStripeAccounts }
import React from 'react';
import Toggle from 'components/toggle'
import { useFeatures } from 'contexts/features';

function Features(props) {
    const { features } = useFeatures();

    return(
        <div className="communication-area selected">
			<table style={{width:'200px',margin:'auto'}}>
                <tbody>
                    {
                        features.map((ft) => {
                            let isEnabled = props.features.find((f) => f.id === ft.id);
                            return <tr key={ft.id}><td><Toggle checked={isEnabled} onChange={(newValue) => props.onChange(ft, newValue)} /></td><td>{ft.label}</td></tr>
                        })
                    }
                </tbody>
            </table>
		</div>
    )
}

export default Features
import React, {useState, useEffect, useRef} from 'react';
import Toggle from 'components/toggle';
import Core from 'util/core';
import PubSub from 'pubsub-js';
import {isSupported} from 'util/contentType';
import { useAuth } from 'contexts/auth';
import { useSettings } from 'contexts/settings';
import { usePage } from 'contexts/page';

function PortalSettings() {
    const [id, setId] = useState('');
    const [logo, setLogo] = useState('');
    const [font, setFont] = useState('');
    const [headerBackgroundColor, setHeaderBackgroundColor] = useState('');
    const [headerColor, setHeaderColor] = useState('');
    const [buttonBackgroundColor, setButtonBackgroundColor] = useState('');
    const [buttonColor, setButtonColor] = useState('');
    const [socialTab, setSocialTab] = useState(true);
    const [activitiesTab, setActivitiesTab] = useState(true);
    const [filesTab, setFilesTab] = useState(true);
    const [storeTab, setStoreTab] = useState(true);
    const [classifiedTab, setClassifiedTab] = useState(true);
    const [rosterTab, setRosterTab] = useState(false);
    const [donateTab, setDonateTab] = useState(true);
    const [ridesTab, setRidesTab] = useState(false);
    const [joinLink, setJoinLink] = useState(true);
    const [startWithSignin, setStartWithSignin] = useState(false);
    const [homePage, setHomePage] = useState(0);
    const fileInput = useRef(null);
    const imageCanvas = useRef(null);
    const auth = useAuth();
    const settings = useSettings();
    const { pages } = usePage();

    useEffect(() => {
        Core.runAction('get_portal_settings', {}, (response) => {
            let ps = response.portalSettings;
            setId(ps.id);
            //setLogo(ps.logo);
            setFont(ps.font);
            setHeaderBackgroundColor(ps.headerBackgroundColor);
            setHeaderColor(ps.headerColor);
            setButtonBackgroundColor(ps.buttonBackgroundColor);
            setButtonColor(ps.buttonColor);
            setSocialTab(ps.socialTab);
            setActivitiesTab(ps.activitiesTab);
            setFilesTab(ps.filesTab);
            setStoreTab(ps.storeTab);
            setClassifiedTab(ps.classifiedTab);
            setRosterTab(ps.rosterTab);
            setDonateTab(ps.donateTab);
            setRidesTab(ps.ridesTab);
            setJoinLink(ps.joinLink);
            setStartWithSignin(ps.startWithSignin);
            setHomePage(ps.homePage);
            if (ps.logo) {
                var img = new Image();
				img.onload = () => placeImageOnCanvas(img);
				img.src = ps.logo;
            }
        });
    }, []);

    const save = () => {
        const data = {
            id: id,
            tenantId: auth.tenant.id,
            logo: logo,
            font: font,
            headerBackgroundColor: headerBackgroundColor,
            headerColor: headerColor,
            buttonBackgroundColor: buttonBackgroundColor,
            buttonColor: buttonColor,
            socialTab: socialTab,
            activitiesTab: activitiesTab,
            filesTab: filesTab,
            storeTab: storeTab,
            classifiedTab: classifiedTab,
            rosterTab: rosterTab,
            donateTab, ridesTab,
            joinLink: joinLink,
            startWithSignin: startWithSignin,
            homePage: homePage
        }
        Core.runAction('update_portal_settings', data, () => {
            PubSub.publish('success', 'Portal Settings saved');
            settings.refreshTenantSettings();
        });
    }
    
    const handleFileSelection = (evt) => {
        var files = evt.target.files;
		// eslint-disable-next-line no-cond-assign
        for (var i = 0, f; f = files[i]; i++) {
            var name = escape(f.name);
            console.log("File Info: " 
                    + "Name: " + name + ", " 
                    + "Type: " + (f.type || 'n/a') + ", "
                    + "Size: " + f.size + " bytes, " 
                    + "last modified: " + (f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : "n/a"));
            if (isSupported(f.type)) {
                var img = new Image();
                // eslint-disable-next-line no-loop-func
                img.onload = () => placeImageOnCanvas(img);
                img.crossOrigin = 'Anonymous';
                img.src = URL.createObjectURL(f);
            } else {
                PubSub.publish('error', f.name + ' is not a supported image type.');
            }
        }
    }

    const selectImage = () => {
        fileInput.current.click();
    }

    const placeImageOnCanvas = (imageObj) => {
        let imgCanvas = imageCanvas.current;
        var context = imgCanvas.getContext('2d');
        context.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
        
        var imageAspectRatio = imageObj.width / imageObj.height;
        var canvasAspectRatio = imgCanvas.width / imgCanvas.height;
        var renderableHeight, renderableWidth, xStart, yStart;

        // If image's aspect ratio is less than canvas's we fit on height
        // and place the image centrally along width
        if(imageAspectRatio < canvasAspectRatio) {
            renderableHeight = imgCanvas.height;
            renderableWidth = imageObj.width * (renderableHeight / imageObj.height);
            xStart = (imgCanvas.width - renderableWidth) / 2;
            yStart = 0;
        }
        // If image's aspect ratio is greater than canvas's we fit on width
        // and place the image centrally along height
        else if(imageAspectRatio > canvasAspectRatio) {
            renderableWidth = imgCanvas.width
            renderableHeight = imageObj.height * (renderableWidth / imageObj.width);
            xStart = 0;
            yStart = (imgCanvas.height - renderableHeight) / 2;
        }

        // Happy path - keep aspect ratio
        else {
            renderableHeight = imgCanvas.height;
            renderableWidth = imgCanvas.width;
            xStart = 0;
            yStart = 0;
        }
        context.drawImage(imageObj, xStart, yStart, renderableWidth, renderableHeight);
        // add the image to the images array
        let image = imgCanvas.toDataURL("image/png", 1.0);
        setLogo(image);
    }

    return (
        <div className='settings-panel'>
            <h4>Logo</h4>
            <hr/>
            <p>Images should be PNG format and no larger than 400x100.</p>
            <input type='file' style={{display:'none'}} ref={fileInput} onChange={handleFileSelection} />
            <div style={{margin:'10px',border:'1px solid gray',width:'400px'}}>
                <div className='image-canvas'>
                    <canvas width='400' height='100' ref={imageCanvas} style={{backgroundImage:`url( ${logo} )`}}></canvas>
                </div>
            </div>
            <button className='secondary' onClick={selectImage}>Change Image</button>
            <div className='spacer' />
            <h4>Home page</h4>
            <select value={homePage} onChange={event => setHomePage(event.target.value)}>
                <option value='0'>Built-in</option>
                {pages.filter(p => p.type < 3).map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
            </select>
            <div className='spacer' />
            <h4>Look and Feel</h4>
            <hr/>
                <h5>Font Family</h5>
                <input type="text" onChange={(evt) => setFont(evt.target.value)} value={font} />
                <h5>Header background color</h5>
                <input type="text" onChange={(evt) => setHeaderBackgroundColor(evt.target.value)} value={headerBackgroundColor} />
                <h5>Header text color</h5>
                <input type="text" onChange={(evt) => setHeaderColor(evt.target.value)} value={headerColor} />
                <h5>Button background color</h5>
                <input type="text" onChange={(evt) => setButtonBackgroundColor(evt.target.value)} value={buttonBackgroundColor} />
                <h5>Button text color</h5>
                <input type="text" onChange={(evt) => setButtonColor(evt.target.value)} value={buttonColor} />
            
            <div className='spacer' />
            <h4>Portal Functionality</h4>
            <hr/>
            <div className='toggle-setting'>
                <Toggle checked={socialTab} onChange={(val) => setSocialTab(val)} /><h5>Show Social Media tab</h5>
            </div>
            <div className='toggle-setting'>
                <Toggle checked={activitiesTab} onChange={(val) => setActivitiesTab(val)}/><h5>Show Activities tab</h5>
            </div>
            <div className='toggle-setting'>
                <Toggle checked={filesTab} onChange={(val) => setFilesTab(val)}/><h5>Show Files tab</h5>
            </div>
            <div className='toggle-setting'>
                <Toggle checked={storeTab} onChange={(val) => setStoreTab(val)}/><h5>Show Store tab</h5>
            </div>
            <div className='toggle-setting'>
                <Toggle checked={classifiedTab} onChange={(val) => setClassifiedTab(val)}/><h5>Show Classifieds tab</h5>
            </div>
            <div className='toggle-setting'>
                <Toggle checked={rosterTab} onChange={(val) => setRosterTab(val)}/><h5>Show Roster tab</h5>
            </div>
            <div className='toggle-setting'>
                <Toggle checked={donateTab} onChange={(val) => setDonateTab(val)}/><h5>Show Donate tab</h5>
            </div>
            <div className='toggle-setting'>
                <Toggle checked={ridesTab} onChange={(val) => setRidesTab(val)}/><h5>Show Rides tab</h5>
            </div>
            <div className='toggle-setting'>
                <Toggle checked={joinLink} onChange={(val) => setJoinLink(val)}/><h5>Show Join link</h5>
            </div>
            <div className='toggle-setting'>
                <Toggle checked={startWithSignin} onChange={(val) => setStartWithSignin(val)}/><h5>Start with signin</h5>
            </div>
            
            <div className='spacer' />
            <h4>Preview</h4>
            <hr/>
            <div className='main-container' style={{width:'400px',height:'120px',border:'1px solid black',position:'relative'}}>
                <div className='top-bar' style={{backgroundColor: headerBackgroundColor,color: headerColor}}>
                    <div className='top-bar-inner'>
                        <div className='top-bar-group'>
                            <div className='top-bar-logo'>
                                {logo !== ''
                                    ? <img src={logo} alt='Group Logo'/>
                                    : <img src={logo} alt='Middletwin Logo'/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='top-bar-apps'>
                        <div className='app-buttons'>
                            <div className='app-button selected' style={{backgroundColor:buttonBackgroundColor, color:buttonColor}}>Dashboard</div>
                            <div className='app-button'>Members</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='button-container'>
                <button onClick={save}>Save Portal Settings</button>
            </div>
        </div>
    )
}

export default PortalSettings;
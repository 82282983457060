import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { formatCurrency } from '_base';
import QRCode from 'react-qr-code';
import { useLoaderData } from 'react-router-dom';
import 'style/Ticket.scss';
import { useCore } from 'contexts/core';

export async function loader({params}) {
    return params;
}

function Ticket() {
    const loaderData = useLoaderData();
    const { runNoAuthAction } = useCore();
    const [id, setId] = useState('')
    const [eventName, setEventName] = useState('');
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [purchaseDate, setPurchaseDate] = useState(null);
    const [purchasePrice, setPurchasePrice] = useState(0);

    useEffect(() => {
        if (loaderData.tenant) {
            let data = {tenantUUID: loaderData.tenant, id: loaderData.id};
            runNoAuthAction('get_ticket', data, response => {
                setId(response.ticket.id);
                setStartDate(response.event.startDate)
                setEndDate(response.event.endDate);
			    setEventName(response.event.name);
			    setDescription(response.eventticket.description);
			    setName(response.ticket.name);
                setEmail(response.ticket.email);
                setPurchaseDate(response.ticket.purchased);
                setPurchasePrice(response.eventticket.price)
            })
        }
    }, [loaderData.tenant])

    return (
        <div className='printable-area portrait'>
            <div className='ticketOuter'>
                <div className='ticket'>
                    <div className='qrcode'><QRCode size={100} value={`https://app.middletwin.com/redeem/${loaderData.tenant}/${id}`}/></div>
                    <div className='detail'>{`${eventName} ${moment(startDate).format('ddd MMM D, YYYY')} - ${moment(endDate).format('ddd MMM D, YYYY')}`}</div>
                    <div className='detail'>{description}</div>
                    <div className='detail'>{`Sold to ${name} (${email})`}</div>
                    <div className='detail' style={{fontSize:'16px'}}>{`Ticket #${id} Sold on ${moment(purchaseDate).format('ddd MMM D, YYYY')} ${formatCurrency(purchasePrice)}`}</div>
                    <div className='info'>Tickets sold through Middletwin, LLC. No Cash Value, Non-refundable. Tickets are only redeemable during the event dates/times.</div>
                </div>
            </div>
        </div>
    )
}

export default Ticket;
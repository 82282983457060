import React, { useState } from  'react';
import AmazingTabs from 'components/amazingTabs';
import MapManager from './maps';
import { EmailTemplatesProvider } from 'contexts/emailTemplates';
import { EventMapsProvider } from 'contexts/eventMaps';
import { VendorsProvider } from 'contexts/vendors';
import Vendors from './vendors';

const tabs = [
    {id: 'maps', label: 'Maps'},
    {id: 'vendors', label: 'Vendors'},
]

function Maps({eventId}) {
    const [view, setView] = useState('maps');

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Maps & Vendors</div>
                </div>
            </div>
            <AmazingTabs tabs={tabs} selected={view} 
                onClick={newView => setView(newView.id)}></AmazingTabs>
            {view === 'maps' &&
                <EmailTemplatesProvider>
                    <EventMapsProvider eventId={eventId}>
                        <MapManager />
                    </EventMapsProvider>
                </EmailTemplatesProvider>
            }
            {view === 'vendors' &&
                <VendorsProvider eventId={eventId}>
                    <Vendors eventId={eventId} />
                </VendorsProvider>
            }
        </div>
    )
}

export default Maps;
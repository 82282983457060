import CharacterCounter from 'apps/phone/messages/characterCounter';
import DateAndTimePicker from 'components/dateAndTimePicker';
import { useEvent } from 'contexts/event';
import React, { useState } from 'react';

function MobileVotingSetup() {
    const { event, updateEventSettings } = useEvent(); 
    const [votingStart, setVotingStart] = useState(event.votingStart);
    const [votingEnd, setVotingEnd] = useState(event.votingEnd);
    const [votingInstructions, setVotingInstructions] = useState(event.votingInstructions);

    const saveHandler = () => {
        updateEventSettings({eventId: event.id, votingStart, votingEnd, votingInstructions})
    }

    return (
        <div style={{margin:'50px'}}>
            <p>For mobile voting you need to complete the following</p>
            <ol>
                <li>In this setup, spectify the date and time that participants can begin voting.<br/>
                (the system will not accept votes before this date and time)</li>
                <li>In this setup, spectify the date and time that voting ends.<br/>
                (the system will not accept votes after this date and time)</li>
                <li>Specify the voting instructions to your participants below in the inscrutions area.</li>
                <li>After saving this setup, go to the Ballots tab, and create your first batch of ballots.</li>
                <li>Print the batch of ballots by selecting the print button above the table and choosing the batch you created in the previous step.</li>
                <li>Create and print additional batches of ballots as needed.</li>
                <li>After voting has ended, go to the results to see and print the results.</li>
            </ol>
            <label>Voting Start</label>
            <DateAndTimePicker value={votingStart} onChange={(newStart) => setVotingStart(newStart)} />
            <label>Voting End</label>
            <DateAndTimePicker value={votingEnd} onChange={(newEnd) => setVotingEnd(newEnd)} />
            <label>Voting Instructions</label>
            <textarea 
                style={{width:'100%',height:'100px'}} 
                value={votingInstructions} 
                onChange={e => setVotingInstructions(e.target.value)} 
                maxLength='200'></textarea>
                <CharacterCounter text={votingInstructions} max={220} />
            <button onClick={saveHandler}>Save</button>
        </div>
    )
}

export default MobileVotingSetup;
import React, {useState} from 'react';
import { useEvents } from 'contexts/events';

function NewEvent({onSave}) {
    const { events } = useEvents();
    const [name, setName] = useState('');
    const [copy, setCopy] = useState('0');
    
    const save = () => {
        let data = {
            name: name,
            reg_price: 0.0, 
            merchant: 0
        }
        if (copy !== '0') {
            data.copy = copy;
        }
        if (onSave) {
            onSave(data);
        }
    }

    return(
        <div>
            <div className='contact-preview'>
                <label>Name</label>
                <input type='text' value={name} onChange={(evt) => setName(evt.target.value)} maxLength='64' />
                <label>Copy Event</label>
                <select value={copy} onChange={(evt) => setCopy(evt.target.value)}>
                    <option value='0'>-Optional-</option>
                    {events.map((e) => <option key={e.id} value={e.id}>{e.name}</option>)}
                </select>
            </div>
            <div className='buttonsHolder'>
                <button onClick={save}>Save</button>
            </div>
        </div>
    )
}

export default NewEvent
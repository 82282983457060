import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const EventProductContext = React.createContext(null);

function EventProductProvider({eventId, children}) {
    const { runAction } = useCore();
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        loadProducts();
    }, [])

    const loadProducts = async() => {
        setLoadingProducts(true);
        await runAction('get_event_products', {eventId}, response => {
            setProducts(response.products);
            setLoadingProducts(false);
        });
    }

    const createProduct = async (data) => {
        await runAction('create_event_product', {...data, eventId}, response => {
            console.log('new product id: ', response.id);
            loadProducts();
        });
    }

    const updateProduct = async (data) => {
        await runAction('update_event_product', {...data, eventId}, response => {
            console.log('updated: ', response.updated);
            loadProducts();
        })
    }

    const deleteProducts = async (ids) => {
        await runAction('delete_event_products', {ids}, response => {
            console.log('deleted: ', response.deleted);
            loadProducts();
        })
    }

    const provider = {
        loadingProducts, loadProducts,
        products,
        createProduct,
        updateProduct,
        deleteProducts
    }

    return <EventProductContext.Provider value={provider}>{children}</EventProductContext.Provider>
}

function useEventProduct() {
    const context = React.useContext(EventProductContext);
    if (!context) {
        throw new Error('useEventProduct must be used within an EventProductProvider');
    }
    return context;
}

export { EventProductProvider, useEventProduct }
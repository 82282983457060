import React, { useRef, useState } from 'react';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import moment from 'moment';
import CampaignDetails from './campaignDetails';
import { EventsProvider } from 'contexts/events';
import IconButton from 'components/iconButton';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { TableSetupProvider } from 'contexts/tableSetup';
import { useCampaigns } from 'contexts/campaigns';

function Campaigns() {
    const { loadingCampaigns, campaigns, loadCampaigns, saveCampaign, deleteCampaigns } = useCampaigns();
    const navigate = useNavigate();
    const [campaign, setCampaign] = useState(null);
    const campaignSettingsPanel = useRef(null);

    const tableFilters = [
        {id: 'filter_recent', label: 'Recent', state: null, isDefault: true},
        {id: 'filter_all', label: 'All', state: 'all'},
        {id: 'queued', label:'Queued', state: 'queued'}
    ];

    const campaignColumns = [
        { id: 'id', label: 'ID', display: false, datum: 'id' },
        { id: 'name', label: 'Name', display: true, datum: 'name' },
        { id: 'status', label: 'Status', style: {width:'100px'}, display: true, formatter: obj => obj.flow ? 'N/A' : obj.status },
        { id: 'flow', label: 'Uses flow', style: {width:'100px'}, display: true, formatter: obj => obj.flow ? 'Yes' : 'No'},
        { id: 'stats', label: 'Sent/Opened', display: true, 
            builder: obj=> {return <IconButton icon={faChartLine} onClick={evt => showCampaignStats(evt, obj)} label={obj.stats.sent + '/' + obj.stats.views}/>}
        },
        {
            id: 'active',
            label: 'Active',
            style: {width:'100px'},
            display: true,
            formatter: (obj) => {
                return obj.active ? 'Yes' : 'No';
            },
        },
        { id: 'audience', label: 'Audience', display: true, datum: 'audience' },
        {
            id: 'created',
            label: 'Created',
            display: true,
            formatter: (obj) => {
                return moment(new Date(obj.created)).format('YYYY-MM-DD');
            },
        },
        {
            id: 'deliveryDate',
            label: 'Delivery Date',
            display: true,
            formatter: (obj) => {
                return moment(new Date(obj.deliveryDate)).format('YYYY-MM-DD');
            },
        }
    ];

    const createCampaignHandler = () => {
        setCampaign(null);
        campaignSettingsPanel.current.show();
    };

    const deleteCampaignHandler = (rows) => {
        const rowIds = rows.map((r) => r.id);
        deleteCampaigns(rowIds);
    };

    const saveCampaignHandler = (data) => {
        saveCampaign(data, () => {
            setCampaign(null);
            campaignSettingsPanel.current.hide();
        });
    };

    const campaignRowClickHandler = (row) => {
        setCampaign(row);
        campaignSettingsPanel.current.show();
    };

    const hideCampaignDetailsHandler = () => {
        setCampaign(null);
    };

    const showCampaignStats = (evt, camp) => {
        evt.preventDefault();
        evt.stopPropagation();
        navigate('/admin/email/stats/' + camp.uuid);
    }

    return (
        <div>
            <TableSetupProvider tableName='campaigns' defaultColumns={campaignColumns}>
                <AmazingTable
                    rows={campaigns}
                    filters={tableFilters}
                    onTableChange={loadCampaigns}
                    onCreate={createCampaignHandler}
                    onCreateLabel='Add campaign'
                    onDelete={deleteCampaignHandler}
                    onRefresh={loadCampaigns}
                    onRowClick={campaignRowClickHandler}
                    loading={loadingCampaigns}
                />
            </TableSetupProvider>
            <SidePanel title='Campaign Settings' ref={campaignSettingsPanel} onHide={hideCampaignDetailsHandler}>
                <EventsProvider>
                    <CampaignDetails
                        campaign={campaign}
                        onSave={saveCampaignHandler}
                    />
                </EventsProvider>
            </SidePanel>
        </div>
    );
}

export default Campaigns;
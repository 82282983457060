import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const EquipmentRentalContext = React.createContext(null);

function EquipmentRentalProvider({children}) {
    const { runAction } = useCore();
    const [loadingRentals, setLoadingRentals] = useState(true);
    const [rentals, setRentals] = useState([]);

    useEffect(() => {
        loadRentals();
    }, [])

    const loadRentals = async () => {
        setLoadingRentals(true);
        await runAction('get_equipment_rentals', {}, response => {
            setRentals(response.rentals);
            setLoadingRentals(false);
        })
    }

    const createRental = async (data) => {
        await runAction('create_equipment_rental', data, () => {
            loadRentals();
        })
    }

    const updateRental = async (data) => {
        await runAction('update_equipment_rental', data, () => {
            loadRentals();
        })
    }

    const deleteRentals = async (ids) => {
        await runAction('delete_equipment_rentals', {ids}, () => {
            loadRentals();
        })
    }

    const provider = {
        loadingRentals, loadRentals,
        rentals,
        createRental,
        updateRental,
    }

    return <EquipmentRentalContext.Provider value={provider}>{children}</EquipmentRentalContext.Provider>
}

function useEquipmentRentals() {
    const context = React.useContext(EquipmentRentalContext);
    if (!context) {
        throw new Error('useEquipmentRentals must be used within an EquipmentRentalProvider');
    }
    return context;
}

export { EquipmentRentalProvider, useEquipmentRentals }
import { formatCurrency } from '_base';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import { useEventProduct } from 'contexts/eventProduct';
import { TableSetupProvider } from 'contexts/tableSetup';
import React, { useRef, useState } from 'react';
import ProductDetails from './productDetails';

function Products() {
    const { loadingProducts, loadProducts, products, deleteProducts } = useEventProduct();
    const [product, setProduct] = useState(null);
    const eventProductPanel = useRef(null);

    const columns = [
        {id: 'id', label:'ID', display: false, datum: 'id'},
        {id: 'name', label:'Name', display: true, datum: 'name'},
        {id: 'price', label: 'Price', display: true, formatter: obj => formatCurrency(obj.price)},
        {id: 'category', label:'Category', display: true, formatter: obj => getCategory(obj.category)},
    ];

    const getCategory = (id) => {
        let cat = 'Other';
        switch(id) {
            case 1: cat='Participant Registration'; break;
            case 2: cat='Swap Meet registration'; break;
            case 3: cat ='Vendor Registration'; break;
            case 4: cat='Merchandise'; break;
            case 5: cat='Spectator'; break;
            case 6: cat='Camping'; break;
            case 7: cat='Membership'; break;
            default: cat='Other'; break;
        }
        return cat;
    }

    const selectProduct = (row) => {
        setProduct(row);
        eventProductPanel.current.show();
    }

    const handleCreateProduct = () => {
        setProduct(null);
        eventProductPanel.current.show();
    }

    const saveProductHandler = () => {
        setProduct(null);
        eventProductPanel.current.hide();
    }

    const handleDeleteProducts = (rows) => {
        const ids = rows.map(p => p.id);
        deleteProducts(ids);
    }

    const handleHideProductPanel = () => {
        setProduct(null);
    }

    return (
        <>
            <TableSetupProvider tableName='eventproduct' defaultColumns={columns}>
                <AmazingTable 
                    rows={products}
                    onRefresh={loadProducts}
                    loading={loadingProducts}
                    onRowClick={selectProduct}
                    onCreate={handleCreateProduct}
                    onCreateLabel='Add product'
                    onDelete={handleDeleteProducts} />
            </TableSetupProvider>
            <SidePanel title='Event Product' ref={eventProductPanel} onHide={handleHideProductPanel}>
                <ProductDetails product={product} onSave={saveProductHandler} />
            </SidePanel>
        </>
    )
}

export default Products;
import { faAward, faBallotCheck, faCalendar, faCarSide, faCog, faDraftingCompass, faMap, faMobileAlt, faPencil, faQrcode, faSitemap, faTablet, faTicket } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from 'react-router-dom';
import {permission, hasPermission} from 'util/permission';
import { useAuth } from 'contexts/auth';

import DetailsPanel from 'components/detailsPanel';
import ShowSettings from './showSettings';
import { EmailTemplatesProvider } from 'contexts/emailTemplates';
import { EventProvider, useEvent } from "contexts/event";
import { EventsProvider } from "contexts/events";
// classes
import Classes from './classes';
import { ClassesProvider } from 'contexts/classes';
// registrations
import Registration from './registration/registration';
import { RegistrationsProvider } from 'contexts/registrations';
// balloting
import Ballot from './ballot/ballot';
import { BallotProvider } from 'contexts/ballot';
// schedules
import Details from './schedules/Details';
// pc voting codes
import Voting from './voting';
import Maps from "./maps";
import EventTickets from "./tickets";
import Sales from "./sales";
import { EventProductProvider } from "contexts/eventProduct";
import { EquipmentRentalProvider } from "contexts/equipmentRental";
import DashcardDesigner from "./dashcardDesigner";
import { EventDashcardProvider } from "contexts/eventDashcard";
import { MakesAndModelsProvider } from "contexts/makesAndModels";
import MakesAndModels from "./makesAndModels/MakesAndModels";
import Loading from "components/loading";
import MobileVoting from "./mobileVoting";

export async function loader({params}) {
    return params;
}

function ShowLanding() {
    const loaderData = useLoaderData();

    return (
        <EventsProvider>
            <EventProvider eventId={loaderData.showId}>
                <ShowLandingApp view={loaderData.view} />
            </EventProvider>
        </EventsProvider>
    )
}

function ShowLandingApp({view}) {
    const { loadEvent, eventLoading, event } = useEvent();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [eventType, setEventType] = useState(0);

    useEffect(() => {
        if (!eventLoading) {
            setEventType(event.type);
        }
    }, [eventLoading])

    useEffect(() => {
        if (!view && !eventLoading) {
            loadEvent();
        }
    }, [view])

    const saveSettingsHandler = () => {
        hideDetailsHandler();
    }

    const hideDetailsHandler = () => {
        navigate('/admin/events/show/' + event.id);
    }

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>{event && (event.name + ' - ' + (eventType === 0 ? 'Basic' : 'Competition Show'))}</div>
                </div>
            </div>
            {!view && eventLoading ? <Loading /> :
                <div className='showLanding'>
                    {hasPermission(user, permission.EVENTADMIN) &&
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/show/' + event.id + '/settings')}>
                            <FontAwesomeIcon icon={faCog} size='6x'/>
                            <h6>Show Settings</h6>
                        </div>
                    }
                    {eventType === 1 && hasPermission(user, permission.EVENTADMIN) &&
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/show/' + event.id + '/classes')}>
                            <FontAwesomeIcon icon={faSitemap} size='6x'/>
                            <h6>Classes</h6>
                        </div>
                    }
                    {eventType === 1 && (hasPermission(user, permission.EVENTADMIN) || hasPermission(user, permission.REGISTRATION)) &&
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/show/' + event.id + '/registrations')}>
                            <FontAwesomeIcon icon={faPencil} size='6x'/>
                            <h6>Participant Registrations</h6>
                        </div>
                    }
                    {eventType === 1 && (hasPermission(user, permission.EVENTADMIN) || hasPermission(user, permission.BALLOTING)) && 
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/show/' + event.id + '/ballot')}>
                            <FontAwesomeIcon icon={faBallotCheck} size='6x'/>
                            <h6>Printed Ballots</h6>
                        </div>
                    }
                    {eventType === 1 && (hasPermission(user, permission.EVENTADMIN) || hasPermission(user, permission.BALLOTING)) &&
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/show/' + event.id + '/voting')}>
                            <FontAwesomeIcon icon={faMobileAlt} size='6x'/>
                            <h6>Mobile Ballots</h6>
                        </div>
                    }
                    {eventType === 1 && (hasPermission(user, permission.EVENTADMIN) || hasPermission(user, permission.SCHEDULE)) && 
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/show/' + event.id + '/schedules')}>
                            <FontAwesomeIcon icon={faCalendar} size='6x'/>
                            <h6>Worker Schedules</h6>
                        </div>
                    }
                    {hasPermission(user, permission.EVENTADMIN) &&
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/show/' + event.id + '/tickets')}>
                            <FontAwesomeIcon icon={faTicket} size='6x'/>
                            <h6>Tickets</h6>
                        </div>
                    }
                    {eventType === 1 && hasPermission(user, permission.EVENTADMIN) &&
                        <div className='showLandingTile' onClick={() => navigate('/results/' + event.id)}>
                            <FontAwesomeIcon icon={faAward} size='6x'/>
                            <h6>Results</h6>
                        </div>
                    }
                    {eventType === 1 && hasPermission(user, permission.EVENTADMIN) &&
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/show/' + event.id + '/codes')}>
                            <FontAwesomeIcon icon={faQrcode} size='6x'/>
                            <h6>Generate peoples choice vote codes</h6>
                        </div>
                    }
                    {eventType === 1 && hasPermission(user, permission.EVENTADMIN) &&
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/show/' + event.id + '/maps')}>
                            <FontAwesomeIcon icon={faMap} size='6x'/>
                            <h6>Maps & Vendors</h6>
                        </div>
                    }
                    {eventType === 1 && hasPermission(user, permission.EVENTADMIN) &&
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/show/' + event.id + '/sales')}>
                            <FontAwesomeIcon icon={faTablet} size='6x'/>
                            <h6>Equipment Rental</h6>
                        </div>
                    }
                    {eventType === 1 && hasPermission(user, permission.EVENTADMIN) &&
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/show/' + event.id + '/dashcard')}>
                            <FontAwesomeIcon icon={faDraftingCompass} size='6x'/>
                            <h6>Dashcard Designer</h6>
                        </div>
                    }
                    {eventType === 1 && hasPermission(user, permission.EVENTADMIN) &&
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/show/' + event.id + '/makesandmodels')}>
                            <FontAwesomeIcon icon={faCarSide} size='6x'/>
                            <h6>Makes & Models</h6>
                        </div>
                    }
                </div>
            }
            <DetailsPanel title={event && event.name} showing={view === 'settings'} onHide={hideDetailsHandler}>
                {event && view === 'settings' && 
                    <EmailTemplatesProvider>
                        <ShowSettings event={event} onBack={saveSettingsHandler} />
                    </EmailTemplatesProvider>
                }
            </DetailsPanel>
            <DetailsPanel title={event && event.name} showing={view === 'classes'} onHide={hideDetailsHandler}>
                {event && view === 'classes' &&
                    <ClassesProvider eventId={event.id}>
                        <Classes />
                    </ClassesProvider>
                }
            </DetailsPanel>
            <DetailsPanel title={event && event.name} showing={view === 'registrations'} onHide={hideDetailsHandler}>
                {event && view === 'registrations' &&
                    <ClassesProvider eventId={event.id}>
                        <RegistrationsProvider eventId={event.id}>
                            <EventDashcardProvider eventId={event.id}>
                                <Registration event={event} />
                            </EventDashcardProvider>
                        </RegistrationsProvider>
                    </ClassesProvider>
                }
            </DetailsPanel>
            <DetailsPanel title={event && event.name} showing={view === 'ballot'} onHide={hideDetailsHandler}>
                {event && view === 'ballot' &&
                    <ClassesProvider eventId={event.id}>
                        <BallotProvider>
                            <Ballot eventId={event.id} cancelBallot={hideDetailsHandler} />
                        </BallotProvider>
                    </ClassesProvider>
                }
            </DetailsPanel>
            <DetailsPanel title={event && event.name} showing={view === 'voting'} onHide={hideDetailsHandler}>
                {event && view === 'voting' &&
                    
                        <MobileVoting eventId={event.id} />
                    
                }
            </DetailsPanel>
            <DetailsPanel title={event && event.name} showing={view === 'schedules'} onHide={hideDetailsHandler}>
                {event && view === 'schedules' &&
                    <Details event={event} />
                }
            </DetailsPanel>
            <DetailsPanel title={event && event.name} showing={view === 'tickets'} onHide={hideDetailsHandler}>
                {event && view === 'tickets' &&
                    <EventTickets event={event} />
                }
            </DetailsPanel>
            <DetailsPanel title={event && event.name} showing={view === 'codes'} onHide={hideDetailsHandler}>
                {event && view === 'codes' &&
                    <div style={{padding:'20px'}}>
                        <Voting eventId={event.id} />
                    </div>
                }
            </DetailsPanel>
            <DetailsPanel title={event && event.name} showing={view === 'maps'} onHide={hideDetailsHandler}>
                {event && view === 'maps' &&
                    <div style={{padding:'20px'}}>
                                <Maps eventId={event.id} />
                    </div>
                }
            </DetailsPanel>
            <DetailsPanel title={event && event.name} showing={view === 'sales'} onHide={hideDetailsHandler}>
                {event && view === 'sales' &&
                    <div style={{padding:'20px'}}>
                        <EventProductProvider eventId={event.id}>
                            <EquipmentRentalProvider>
                                <Sales />
                            </EquipmentRentalProvider>
                        </EventProductProvider>
                    </div>
                }
            </DetailsPanel>
            <DetailsPanel title={event && event.name} showing={view === 'dashcard'} onHide={hideDetailsHandler}>
                {event && view === 'dashcard' &&
                    <div style={{padding:'20px'}}>
                        <EventDashcardProvider eventId={event.id}>
                            <DashcardDesigner />
                        </EventDashcardProvider>
                    </div>
                }
            </DetailsPanel>
            <DetailsPanel title={event && event.name} showing={view === 'makesandmodels'} onHide={hideDetailsHandler}>
                {event && view === 'makesandmodels' &&
                    <div style={{padding:'20px'}}>
                        <MakesAndModelsProvider eventId={event.id}>
                            <MakesAndModels />
                        </MakesAndModelsProvider>
                    </div>
                }
            </DetailsPanel>
        </div>
    )
}

export default ShowLanding;
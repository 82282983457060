import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const TicketContext = React.createContext(null);

function TicketProvider({eventId, children}) {
    const { runAction } = useCore();
    const [loadingTickets, setLoadingTickets] = useState(true);
    const [tickets, setTickets] = useState([]);
    const [creatingTicket, setCreatingTicket] = useState(false);
    const [randomTicket, setRandomTicket] = useState(null);

    useEffect(() => {
        loadTickets();
    }, [eventId])
    
    const loadTickets = async () => {
        setLoadingTickets(true);
        const data = {event: eventId}
        await runAction('get_tickets', data, (response) => {
            if (response && response.tickets) {
                setTickets(response.tickets);
            }
            setLoadingTickets(false);
        })
    }

    const createTicket = async (ticketData, callback) => {
        setCreatingTicket(true);
        await runAction('create_ticket', {...ticketData, event: eventId}, response => {
            setCreatingTicket(false);
            loadTickets();
            callback(response.tickets);
        })
    }

    const deleteTickets = async (ids) => {
        await runAction('delete_tickets', {ids}, () => {
            loadTickets();
        });
    }

    const pickRandomTicket = async (eventTicketId) => {
        const data = {eventId, eventTicketId};
        await runAction('get_random_ticket', data, response => {
            setRandomTicket(response.randomTicket);
        });
    }

    const provider = {
        loadingTickets, tickets,
        loadTickets,
        createTicket, creatingTicket,
        deleteTickets,
        randomTicket, pickRandomTicket,
    }

    return <TicketContext.Provider value={provider}>{children}</TicketContext.Provider>
}

function useTicket() {
    const context = React.useContext(TicketContext);
    if (!context) {
        throw new Error('useTicket must be used within a TicketProvider');
    }
    return context;
}

export { TicketProvider, useTicket }
import React, {useState, useEffect} from 'react';
import 'style/t166dashCard.scss';
import ShelbyCobra from 'assets/dashCard/ShelbyCobra.jpg';
import nsmcLogo from 'assets/dashCard/TPMFC.jpg';
import MustangLogo from 'assets/dashCard/MustangLogo.jpg';
import Horse from 'assets/dashCard/Horse.jpg';
import { useClasses } from 'contexts/classes';

function T166DashCard({entryData}) {
    const { classes } = useClasses();
    const [name, setName] = useState('');

    useEffect(() => {
        if (entryData) {
            setName(entryData.fname + ' ' + entryData.lname);
        }
    }, [entryData]);

    return (
        <div style={{backgroundColor:'gray'}}>
            <div className='t166dashCard'>
                <div className='dashCardContainer'>
                    <div className='logosContainer'>
                        <img src={ShelbyCobra} style={{width:'200px',height:'200px'}} />
                        <img src={nsmcLogo} />
                        <img src={MustangLogo} style={{width:'200px',height:'200px'}}/>
                    </div>
                    <div className='fieldsTable' style={{backgroundImage:`url(${Horse})`,backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:'center center'}}>
                        <table cellPadding={0} cellSpacing={0}>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='fieldData'>
                                            <div className='label'>Car#:</div>
                                            <div className='field' style={{fontSize:'60px'}}>{entryData.car_number}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='fieldData'>
                                            <div className='label'>Class:</div>
                                            <div className='field' style={{fontSize:'24px'}}>{`${entryData.car_class} - ${classes.find(c => c.id == entryData.car_class)?.description}`}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='fieldData'>
                                            <div className='label'>Year:</div>
                                            <div className='field'>{entryData.year}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='fieldData'>
                                            <div className='label'>Make:</div>
                                            <div className='field'>{entryData.make}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='fieldData'>
                                            <div className='label'>Model:</div>
                                            <div className='field'>{entryData.model}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='fieldData'>
                                            <div className='label'>Engine:</div>
                                            <div className='field'>{entryData.engine}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='fieldData'>
                                            <div className='label'>Owner:</div>
                                            <div className='field'>{name}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='fieldData'>
                                            <div className='label'>City:</div>
                                            <div className='field'>{entryData?.trim || ' '}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <div className='fieldData'>
                                            <div className='label'>Comments:</div>
                                            <div className='field'>{entryData.notes}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default T166DashCard;
import React from 'react';
import { useAuth } from 'contexts/auth';
import Logins from './logins';
import { LoginsProvider } from 'contexts/logins';

function LoginsApp() {
    const { tenant, group} = useAuth();

    return(
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Logins</div>
                </div>
            </div>
            <LoginsProvider type={tenant ? 'account' : group ? 'group' : 'admin'} resourceId={tenant ? tenant.id : group ? group.id : 1} >
                <Logins type={tenant ? 'account' : group ? 'group' : 'admin'} resourceId={tenant ? tenant.id : group ? group.id : 1} />
            </LoginsProvider>
        </div>
    )
}

export default LoginsApp;
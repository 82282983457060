import { useEventMap } from 'contexts/eventMap';
import { useReservations } from 'contexts/reservations';
import React, { createRef, useEffect, useState } from 'react';

function MapElement({element, showReservations, onSelect, onResize, onMove}) {
    const { selections } = useEventMap();
    const { reservations } = useReservations();
    const [isResizing, setIsResizing] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [startWidth, setStartWidth] = useState(0);
    const [startHeight, setStartHeight] = useState(0);
    const [currentHandle, setCurrentHandle] = useState(null);
    // dragging
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({x: 0, y: 0});
    const elementRef = createRef(null);

    const selectElement = (evt) => {
        const selection = {element: element, node: elementRef.current};
        onSelect(selection);
        evt.preventDefault();
        evt.stopPropagation();
    }

    useEffect(() => {
        if (selections.length > 0) {
            const isSelected = selections.find(s => s.element.id == element.id) ? true : false;
            if (isSelected && isDragging) {
                stopDrag();
            }
        } else {
            stopDrag();
        }
    }, [selections])

    useEffect(() => {
        if (isResizing) {
            document.addEventListener('mousemove', resize);
            document.addEventListener('mouseup', stopResize);
        }
    }, [isResizing])

    // Add effect to handle drag events
    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleDrag);
            document.addEventListener('mouseup', stopDrag);
        }
        return () => {
            document.removeEventListener('mousemove', handleDrag);
            document.removeEventListener('mouseup', stopDrag);
        };
    }, [isDragging, dragStart]);

    // initialize resizing
    const initResize = (evt) => {
        // get the starting x and y position
        setStartX(evt.clientX);
        setStartY(evt.clientY);
        setStartWidth(element.w);
        setStartHeight(element.h);
        // set the handle the user is using to resize
        setCurrentHandle(evt.target);
        // set the is resizing flag
        setIsResizing(true);
        evt.preventDefault();
    }
    
    // resize the element
    function resize(e) {
        if (!isResizing) return;
    
        const deltaX = e.clientX - startX;
        const deltaY = e.clientY - startY;
    
        let newWidth = startWidth;
        let newHeight = startHeight;
    
        // Adjust dimensions based on which handle is being dragged
        if (currentHandle.classList.contains('se')) {
            newWidth = startWidth + deltaX;
            newHeight = startHeight + deltaY;
        }
        
        // Set minimum dimensions
        newWidth = Math.max(10, newWidth);
        newHeight = Math.max(10, newHeight);
    
        onResize({newWidth, newHeight});
    }

    // stop resizing
    function stopResize() {
        setIsResizing(false);
        document.removeEventListener('mousemove', resize);
        document.removeEventListener('mouseup', stopResize);
    }
    
    // Add new function to handle drag start
    const startDrag = (evt) => {
        // Only start drag if we're not resizing and it's a left click
        if (!isResizing && evt.button === 0) {
            setIsDragging(true);
            setDragStart({
                x: evt.clientX - element.x,
                y: evt.clientY - element.y
            });
            evt.preventDefault();
            evt.stopPropagation();
        }
    };

    // Add function to stop dragging
    const stopDrag = () => {
        setIsDragging(false);
    };

    // Add function to handle dragging
    const handleDrag = (evt) => {
        if (!isDragging) return;
        const isSelected = selections.findIndex(s => s.element.id == element.id) > -1;
        if (!isSelected) return;

        const newX = evt.clientX - dragStart.x;
        const newY = evt.clientY - dragStart.y;
        
        onMove({newX, newY});
        
        evt.preventDefault();
        evt.stopPropagation();
    };

    const createElement = () => {
        const reservation = reservations.find(r => r.eventMapElementId == element.id);
        const isSelected = selections.find(s => s.element.id == element.id) ? true : false;
        const fill = showReservations && reservation ? '#CCC' : '#FFF';
        const stroke = isSelected ? '#F00' : '#000';
        const elementId = `p-${element.id}`;
        let elem = null;
        switch(element.shape) {
            case 'box': 
                elem = <g><path 
                            id={elementId} stroke={stroke} fill={fill} pathLength='1'
                            d={`M${element.x},${element.y} L${(element.x+element.w)},${element.y} L${(element.x+element.w)},${(element.y+element.h)} L${element.x},${(element.y+element.h)} Z`}
                            transform={!isNaN(element.a) ? `rotate(${element.a},${element.x},${element.y})` : null} 
                            onClick={evt => selectElement(evt)} 
                            onMouseDown={startDrag}
                            style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                            ref={elementRef} />
                        {isSelected && 
                        <>
                            <circle className='resize-handle se' cx={element.x + element.w} cy={element.y + element.h} r="5" onMouseDown={initResize} onMouseUp={stopResize}/>
                        </>
                        }
                        <text style={{fontSize:'12px',cursor: isDragging ? 'grabbing' : 'grab'}} dy={12}
                            onClick={evt => selectElement(evt)}
                            onMouseDown={startDrag}>
                        <textPath xlinkHref={`#${elementId}`} startOffset='2%' >{`${element.row}${element.num}`}</textPath></text>
                    </g>
                break;
            case 'circle': 
                elem = <circle id={elementId} stroke={stroke} fill={fill}
                    cx={element.x} cy={element.y} r={element.w} 
                    onClick={(evt) => selectElement(evt)} ref={elementRef} />
                break;
            default: break;
        }
        return elem;
    }

    return (
        <>
            {createElement()}
        </>
    )
}

export default MapElement;
import { useEventDashcard } from 'contexts/eventDashcard';
import React, { useState } from 'react';
import moment from 'moment';
import { TableSetupProvider } from 'contexts/tableSetup';
import AmazingTable from 'components/amazingTable';
import DashcardDesignStudio from './dashcardDesignStudio';
import Modal from 'components/modal';
import baseDashcards from './baseDashcards.jsx';

function DashcardDesigner() {
    const { loadingCards, loadDashcards, dashcards, deleteDashcards } = useEventDashcard();
    const [showDashcard, setShowDashcard] = useState(false);
    const [dashcard, setDashcard] = useState(null);
    const [showNewDashcardModal, setShowNewDashcardModal] = useState(false);
    const [startingPoint, setStartingPoint] = useState('');
    const _templates = baseDashcards;

    const showDahcardHandler = (card) => {
        setDashcard(card);
        setShowDashcard(true);
    }

    const handleDashcardDone = () => {
        setDashcard(null);
        setShowDashcard(false);
    }

    const removeDashcards = (rows) => {
        const ids = rows.map(r => r.id);
        deleteDashcards(ids);
    }

    const startDashcardHandler = () => {
        setShowNewDashcardModal(false);
        setDashcard(startingPoint === '' ? null : {content: JSON.stringify(_templates[startingPoint].boxes), name: 'New Dashcard'});
        setShowDashcard(true);
    }

    const cancelNewDashcardHandler = () => {
        setShowNewDashcardModal(false);
    }

    const columns = [
        {id: 'id', label:'ID', display: false, datum: 'id'},
        {id: 'name', label:'Name', display: true, datum: 'name'},
        {id: 'created', label: 'Created', display: true, formatter: obj => moment(obj.created).format('ddd, MMM Do YYYY')},
    ];

    return (
        <div>
            {!showDashcard ?
            <TableSetupProvider tableName='dashcards' defaultColumns={columns}>
                <AmazingTable 
                    rows={dashcards}
                    onRefresh={loadDashcards}
                    onRowClick={showDahcardHandler}
                    onCreate={() => setShowNewDashcardModal(true)}
                    onCreateLabel='Add dashcard'
                    onDelete={removeDashcards}
                    loading={loadingCards} />
            </TableSetupProvider>
            :
            <div>
                <DashcardDesignStudio dashcard={dashcard} onDone={handleDashcardDone}/>
            </div>
            }
            <Modal
                title='New dashcard' showing={showNewDashcardModal} 
                onHide={() => {setShowNewDashcardModal(false)}}
                buttons={[
                    {type: 'primary', handler:() => startDashcardHandler(), label: 'Start'},
                    {type: 'secondary', handler:() => cancelNewDashcardHandler(), label: 'Cancel'}
            ]}>
                <p style={{width: '350px'}}>
                    Select a starting point to use for your new dashcard.
                </p>
                <label>Starting point</label>
                <select value={startingPoint} onChange={e => setStartingPoint(e.target.value)}>
                    <option value=''>Blank</option>
                    <option value='basic'>Basic</option>
                    <option value='gridStyle'>Grid Layout</option>
                </select>
            </Modal>
        </div>
    )
}

export default DashcardDesigner;
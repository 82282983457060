import React, { useEffect, useRef, useState } from 'react';
import AmazingTable from 'components/amazingTable';
import moment from 'moment';
import SidePanel from 'components/sidePanel';
import DetailsPanel from 'components/detailsPanel';
import MemberDetails from './memberDetails';
import DataImport from 'components/dataImport';
import { useAuth } from 'contexts/auth';
import { useMemberTypes } from 'contexts/memberTypes';
import { useMembers } from 'contexts/members';
import { useNavigate } from 'react-router-dom';
import { MemberProvider } from 'contexts/member';
import CsvImport from 'components/csvImport';
import Modal from 'components/modal';
import { CountriesProvider } from 'contexts/countries';
import { PaginationProvider } from 'contexts/pagination';
import { formatPhoneNumber } from '_base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faLink } from '@fortawesome/pro-regular-svg-icons';
import { TableSetupProvider } from 'contexts/tableSetup';
import Checkbox from 'components/checkbox';
import DatePicker from 'react-datepicker';
import { PillBoxProvider } from 'contexts/tagPillbox';
import PillBox from 'components/tagPillbox';

const tableFilters = [
    {id: 'filter_none', label: 'None', state: null, isDefault: true},
    {id: 'filter_new', label: 'New', state: 'new'},
    {id: 'filter_active', label: 'Active', state: 'active'}, 
    {id: 'filter_expired', label: 'Expired', state: 'expired'}, 
    {id: 'filter_expiring', label: 'Expiring soon', state: 'expiring'}, 
    {id: 'filter_updated', label: 'Recently updated', state: 'updated'},
    {id: 'filter_newsletter', label: 'Newsletter', state: 'newsletter_list'},
    {id: 'filter_newsletter_mail', label: 'Mail newsletter', state: 'newsletter_mail'},
    {id: 'filter_newsletter_email', label: 'Email newsletter', state: 'newsletter_email'},
    {id: 'filter_roster', label: 'Roster', state: 'roster'}
];

const tableSorters = [
    {id: 'sort_lname', label: 'Last Name', order: 'lname', isDefault: true}, 
    {id: 'sort_fname', label: 'First Name', order: 'fname'},
    {id: 'sort_id', label: 'Member Id', order: 'id'}
];

const importFields = [
    {id: 'id', label: 'Id', defaults: ['all'], hint: 'number'},
    {id: 'altMemberId', label: 'Alternate Id', defaults: ['all'], hint: '64 max'},
    {id: 'title', label: 'Title', defaults: ['all'], hint: '64 max'},
    {id: 'fname', label: 'First Name', defaults: ['all'], hint: '64 max'},
    {id: 'lname', label: 'Last Name', defaults: ['all'], hint: '64 max'},
    
    {id: 'partner', label: 'Partner', defaults: ['all'], hint: '64 max'},
    {id: 'address', label: 'Address', defaults: ['all'], hint: '128 max'},
    {id: 'city', label: 'City', defaults: ['all'], hint: '64 max'},
    {id: 'state', label: 'State', defaults: ['all'], hint: '32 max'},
    {id: 'zip', label: 'Zip Code', defaults: ['all'], hint: '16 max'},
    {id: 'country', label: 'Country', defaults: ['all'], hint: '32 max'},
    {id: 'comments', label: 'Comments', defaults: ['all'], hint: '1024 max'},
    {id: 'mobile', label: 'Mobile', defaults: ['all'], hint: '24 max'},
    {id: 'phone', label: 'Phone', defaults: ['all'], hint: '24 max'},
    {id: 'email_address', label: 'Email', defaults: ['all'], hint: '128 max'},
    {id: 'tags', label: 'Tags', defaults: ['complete'], hint: 'tag|tag'},
    {id: 'type', label: 'Member Type', defaults: ['all'], hint: 'Number (id for member type)'},
    {id: 'join_date', label: 'Join Date', defaults: ['all'], hint: 'YYYY-MM-DD'},
    {id: 'expiration', label: 'Expiration Date', defaults: ['all'], hint: 'YYYY-MM-DD'}
];

function Members () {
    const navigate = useNavigate();
    const { loadingMemberTypes, memberTypes } = useMemberTypes();
    const { loadingMembers, members, limit, offset, fullCount, loadMembers, deleteMembers, importMembers, createMember, bulkUpdate } = useMembers();
    const [selectedMember, setSelectedMember] = useState(null);
    const [newMember, setNewMember] = useState(null);
    const [showImportPanel, setShowImportPanel] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const sidePanel = useRef(null);
    const dataImport = useRef(null);
    const { tenant } = useAuth();
    const [membersToDelete, setMembersToDelete] = useState([]);
    const [membersToEdit, setMembersToEdit] = useState([]);
    const [propertyToEdit, setPropertyToEdit] = useState('');
    const [propertyValue, setPropertyValue] = useState('');
    const [tagFilter, setTagFilter] = useState('');

    const phoneNumberBuilder = (obj) => {
        if (obj.phoneNumbers.length > 0) {
            return <div key='member-phone'>{formatPhoneNumber(obj.phoneNumbers[0].longCode)}</div>
        }
        return <div/>;
    }

    const tagBuilder = (obj) => {
        if (obj.tags.length > 0) {
            return <div key='member-pills' className='pillbox'>{obj.tags.map(t => <div key={t.id} className='tag' label={t.text}>{t.text}</div>)}</div>;
        }
        return <div/>;
    };

    const memberTypeBuilder = (obj) => {
        if (obj.parentId !== 0) {
            return <div key='member-type' style={{width:'100px'}}><FontAwesomeIcon icon={faLink} /> Member added</div>
        }
        let memberType = memberTypes.find((mt) => mt.id === obj.type);
        if (memberType) {
            return <div key='member-type' style={{width:'100px'}}><span>{memberType.label}</span></div>
        }
        return <div key='member-type' style={{width:'100px'}}/>;
    }

    const tableColumns = [
        {id: 'id', label:'ID', display: false, style:{width:'100px'}, datum: 'id'},
        {id: 'altMemberId', label: 'Alternate ID', display: false, datum:'altMemberId'},
        {id: 'title', label: 'Title', display: true, datum: 'title', style:{width: '50px'}},
        {id: 'name', label:'Name', display: true, formatter: (obj) => {return obj.fname + ' ' + obj.lname;}},
        {id: 'partner', label: 'Partner', display: true, datum: 'partner', style:{width:'100px'}},
        {id: 'type', label: 'Type', display: true, builder: memberTypeBuilder, style:{width:'100px'}},
        {id: 'email_address', label: 'Email', display: true, datum: 'email_address'},
        {id: 'phone', label: 'Phone', display: true, builder: phoneNumberBuilder},
        {id: 'join_date', label: 'Join date', display: true, formatter: (obj) => {return moment(obj.join_date).format('YYYY-MM-DD');}, style:{width:'100px'}},
        {id: 'update_date', label: 'Updated date', display: true, formatter: (obj) => {return moment(obj.update_date).format('YYYY-MM-DD');}, style:{width:'100px'}},
        {id: 'expiration', label: 'Expiration date', display: true, formatter: (obj) => {return moment(obj.expiration).format('YYYY-MM-DD');}, style:{width:'100px'}},
        {id: 'address', label: 'Address', display: true, datum: 'address'},
        {id: 'city', label: 'City', display: true, datum: 'city'},
        {id: 'state', label: 'State', display: true, datum: 'state', style:{width:'100px'}},
        {id: 'zip', label: 'Postal code', display: true, datum: 'zip', style:{width:'100px'}},
        {id: 'country', label: 'Country', display: true, datum: 'country', style:{width:'100px'}},
        {id: 'birth_date', label: 'Birth date', display: true, formatter: function(obj) {return obj.birth_date !== null ? moment(obj.birth_date).format('YYYY-MM-DD') : '';}, style:{width:'100px'}},
        {id: 'tags', label: 'Tags', display: true, builder: tagBuilder},
        {id: 'parentid', label: 'Parent ID', display: false, datum: 'parentId'}
    ];

    const confirmDelete = (members) => {
        setMembersToDelete(members);
        setShowDeleteModal(true);
    }

    const removeMembers = () => {
        const memberIds = membersToDelete.map((m) => m.id);
        deleteMembers(memberIds);
        setMembersToDelete([]);
    }
    const rowClickHandler = (row) => {
        setSelectedMember(row);
    }
    const detailsHideHandler = () => {
        setSelectedMember(null);
    }
    const memberSavedHander = () => {
        setSelectedMember(null);
        loadMembers({});
        setCurrentPage(1);
    }
    const rowSelectHandler = (row, value) => {
        console.log(row);
        console.log(value);
    }
    const editHandler = (rows) => {
        if (rows.length === 1) {
            setSelectedMember(rows[0]);
        } else if (rows.length > 0) {
            setMembersToEdit(rows);
            setShowEditModal(true);
        }
    }
    const createNewMember = () => {
        let newMember = {fname: '', lname: '', email_address: '', type: 1};
        setNewMember(newMember);
        sidePanel.current.show();
    }
    const exportHandler = (filter, cols) => {
        window.open('/dart/core?action=get_all_members&type=' + filter + '&format=csv&columns=' + cols + '&tenantUUID=' + tenant.uuid);
    }
    const importHandler = () => {
        setShowImportPanel(true);
    }
    const labelHandler = (filter) => {
        navigate(`/labels/${filter}/${tenant.uuid}/0`);
    }
    const memberCardsHandler = (filter) => {
        navigate(`/membercards/${tenant.uuid}/${filter}`);
    }
    const rosterHandler = (filter) => {
        navigate(`/roster/${tenant.uuid}/${filter}`);
    }
    const handleImport = (map, objects) => {
        importMembers(map, objects, () => dataImport.current.hide());
        importMembers(map, objects, () => setShowImportPanel(false));
    }
    const onPrevPage = (newPage) => {
        if (offset !== 0) {
            loadMembers({offset: offset - limit});
            setCurrentPage(newPage);
        }
    }
    const onNextPage = (newPage) => {
        if (offset + limit < fullCount) {
            loadMembers({offset: offset + limit});
            setCurrentPage(newPage);
        }
    }
    
    const tableChangeHandler = (params) => {
        loadMembers(params);
        setCurrentPage(1);
    }

    /**
	 * handle the change of an input
	 */
	const handleChange = (event) => {
		let _newMember = Object.assign({}, newMember);
        _newMember[event.target.name] = event.target.value;
		setNewMember(_newMember);
    }
    const saveNewMember = () => {
        sidePanel.current.hide();
        createMember(newMember);
    }
    useEffect(() => {
        if (propertyToEdit.startsWith('text_')) {
            setPropertyValue('');
        } else if (propertyToEdit.startsWith('checkbox')) {
            setPropertyValue(false);
        } else if (propertyToEdit.endsWith('_date')) {
            setPropertyValue(Date.now());
        } else if (propertyToEdit === 'title' || propertyToEdit === 'type') {
            setPropertyValue('');
        } else if (propertyToEdit === 'tags') {
            setPropertyValue([]);
        }
    }, [propertyToEdit]);
    const hideBulkPropertyDialog = () => {
        setShowEditModal(false);
        setMembersToEdit([])
        setPropertyToEdit('');
        setPropertyValue('');
    }
    const bulkUpdateHandler = () => {
        // get the array of member ids that we want to change
        const memberIds = membersToEdit.map((m) => m.id);
        // get the property that we want to change
        const regex = /^(text_|checkbox_)/;
        const property = propertyToEdit.replace(regex, '');
        // create the data to send to the server
        const data = {memberIds, property, value: propertyValue}
        bulkUpdate(data, () => {
            hideBulkPropertyDialog();
        });
    }
    return(
        <div>
            {!loadingMemberTypes &&
                <>
                    <TableSetupProvider tableName='members' defaultColumns={tableColumns}>
                        <PaginationProvider 
                            currentPage={currentPage}
                            maxPages={Math.ceil(fullCount/limit)} 
                            previousPageHandler={onPrevPage} 
                            nextPageHandler={onNextPage}>
                            <AmazingTable
                                hasSearch={true}
                                hasLimitSelector={true}
                                hasPagination={true}
                                filters={tableFilters} 
                                sorters={tableSorters}
                                rows={members} 
                                onRefresh={loadMembers}
                                onTableChange={tableChangeHandler}
                                onRowClick={rowClickHandler}
                                onRowSelect={rowSelectHandler}
                                allowMultiRowEdit={true}
                                onEdit={editHandler}
                                onCreate={createNewMember}
                                onCreateLabel='Add member'
                                onDelete={confirmDelete}
                                onExport={exportHandler}
                                onImport={importHandler}
                                onLabel={labelHandler}
                                onCards={memberCardsHandler}
                                onRoster={rosterHandler}
                                loading={loadingMembers}
                            />
                        </PaginationProvider>
                    </TableSetupProvider>
                    <SidePanel title='New Member' ref={sidePanel}>
                        {newMember ?
                        <div style={{width:'350px'}}>
                            <p>Please tell us the members first and last name and we will create a new record for this member. Then you can select 
                                the new member from the member list to fill in the rest of the details.
                            </p>
                            <label>First name</label>
                            <div><input type='text' name='fname' value={newMember.fname} onChange={handleChange} maxLength='64' /></div>
                            <label>Last name</label>
                            <div><input type='text' name='lname' value={newMember.lname} onChange={handleChange} maxLength='64' /></div>
                            <label>Email</label>
                            <div><input type='text' name='email_address' value={newMember.email_address} onChange={handleChange} maxLength='64' /></div>
                            <label>Membership Type</label>
                            <div>
                                <select name='type' value={newMember.type} onChange={handleChange}>
                                    {memberTypes.map((t) => <option key={t.id} value={t.id}>{t.label}</option>)}
                                </select>
                            </div>
                            <div className='button-container'>
                                <button onClick={saveNewMember}>Save</button>
                            </div>
                        </div>
                        : null }
                    </SidePanel>
                    <DetailsPanel title='Details' showing={selectedMember !== null} onHide={detailsHideHandler}>
                        <CountriesProvider>
                            {selectedMember 
                                ? <MemberProvider memberId={selectedMember.id}>
                                        <MemberDetails 
                                            memberId={selectedMember.id} 
                                            onSave={memberSavedHander}
                                            onBack={detailsHideHandler} />
                                    </MemberProvider>
                                : null }
                        </CountriesProvider>
                    </DetailsPanel>
                    <Modal title='CSV Import' showing={showImportPanel} onHide={() => setShowImportPanel(false)}>
                        <CsvImport onImport={handleImport} targetFields={importFields} />
                    </Modal>
                    <Modal title='Delete Members' showing={showDeleteModal} onHide={() => {setShowDeleteModal(false); setMembersToDelete([])}}>
                        <div>Are you sure you want to delete the selected members?</div>
                        <div className='button-container'>
                            <button onClick={() => {removeMembers(); setShowDeleteModal(false);}}>Yes, Delete</button>
                        </div>
                    </Modal>
                    <Modal 
                        title={`Bulk edit ${membersToEdit.length} members`} 
                        showing={showEditModal} 
                        onHide={() => hideBulkPropertyDialog()}
                        buttons={[
                            {type: 'primary', handler:() => bulkUpdateHandler(), label: 'Update', 
                                disabled: propertyToEdit.length === 0},
                            {type: 'secondary', handler:() => hideBulkPropertyDialog(), label: 'Cancel'}
                        ]}
                    >
                        <div style={{width:'400px'}}>
                            <label>Property to update</label>
                            <select value={propertyToEdit} onChange={evt => setPropertyToEdit(evt.target.value)}>
                                <option value=''>Choose one</option>
                                <optgroup label='Personal Details'>
                                    <option value='text_fname'>First Name</option>
                                    <option value='text_lname'>Last Name</option>
                                    <option value='title'>Title</option>
                                    <option value='text_partner'>Partner</option>
                                    <option value='text_address'>Address</option>
                                    <option value='text_city'>City</option>
                                    <option value='text_state'>State</option>
                                    <option value='text_zip'>Postal code</option>
                                </optgroup>
                                <optgroup label='Member Settings'>
                                    <option value='checkbox_notify_event'>Notify of new events</option>
                                    <option value='checkbox_notify_file'>Notify of new files</option>
                                    <option value='checkbox_notify_product'>Notify of new products</option>
                                    <option value='checkbox_newsletter'>Mail newsletter</option>
                                    <option value='checkbox_enewsletter'>EMail newsletter</option>
                                    <option value='checkbox_autorenew'>Auto renew</option>
                                    <option value='checkbox_roster'>List in directory/roster</option>
                                    <option value='checkbox_moderator'>Portal moderator</option>
                                </optgroup>
                                <optgroup label='Tags'>
                                    <option value='tags'>Tags</option>
                                </optgroup>
                                <optgroup label='Membership'>
                                    <option value='type'>Membership type</option>
                                    <option value='join_date'>Join date</option>
                                    <option value='exp_date'>Expiration date</option>
                                </optgroup>
                            </select>    
                            {propertyToEdit.startsWith('text_') &&
                                <>
                                    <label>Value</label>
                                    <div>
                                        <input type='text' value={propertyValue} onChange={evt => setPropertyValue(evt.target.value)} />
                                    </div>
                                </>
                            }
                            {propertyToEdit.startsWith('checkbox_') &&
                                <>
                                    <label>Setting</label>
                                    <Checkbox checked={propertyValue} onChange={() => setPropertyValue(!propertyValue)} />
                                </>
                            }
                            {propertyToEdit.endsWith('_date') && typeof propertyValue === 'number' &&
                                <>
                                    <label>New date</label>
                                    <div className='date-picker'>
                                        <DatePicker selected={moment(propertyValue).toDate()} onChange={d => setPropertyValue(d.getTime())}/>
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </div>
                                </>
                            }
                            {propertyToEdit === 'title' &&
                                <>
                                    <label>Title</label>
                                    <select value={propertyValue} onChange={e => setPropertyValue(e.target.value)}>
                                        <option value=''>-None-</option>
                                        <option value='Mr'>Mr</option>
                                        <option value='Mrs'>Mrs</option>
                                        <option value='Miss'>Miss</option>
                                        <option value='Ms'>Ms</option>
                                        <option value='Dr'>Dr</option>
                                    </select>
                                </>
                            }
                            {propertyToEdit === 'tags' && typeof propertyValue === 'object' &&
                                <>
                                    <label>Tags</label>
                                    <input type='text' placeholder='Filter' value={tagFilter} onChange={event => setTagFilter(event.target.value)} />
                                    <PillBoxProvider tags={propertyValue} onChange={setPropertyValue}><PillBox filter={tagFilter}/></PillBoxProvider>
                                </>
                            }
                            {propertyToEdit === 'type' &&
                                <>
                                    <label>Membership Type</label>
                                    <div>
                                        <select name='type' value={propertyValue} onChange={evt => setPropertyValue(evt.target.value)}>
                                            <option value=''>Choose one</option>
                                            {memberTypes.map((t) => <option key={t.id} value={t.id}>{t.label}</option>)}
                                        </select>
                                    </div>
                                </>
                            }
                        </div>
                    </Modal>
                    <DataImport 
                        title='Import Members' 
                        targetFields={importFields} 
                        ref={dataImport} 
                        onImport={handleImport} />
                </>
            }
        </div>
    );
}

export default Members;
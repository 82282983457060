import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const MembersContext = React.createContext(null);

function MembersProvider({google = '', tag = null, children}) {
    const { runAction } = useCore();
    const [loadingMembers, setLoadingMembers] = useState(true);
    const [members, setMembers] = useState([]);
    const [fullCount, setFullCount] = useState(0);
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState('');
    const [gooogle, setGooogle] = useState('');
    const [sorter, setSorter] = useState('lname');

    useEffect(() => {
        loadMembers({google, tag});
    }, [google, tag])

    const loadMembers = async (search) => {
        setLoadingMembers(true);
        const _filter = search.filter ? search.filter : filter;
        const _google = search.google ? search.google : gooogle;
        const _sorter = search.sorter ? search.sorter : sorter;
        const _limit = search.limit ? search.limit : limit;
        const _offset = search.offset ? search.offset : 0;
        const tag = search.tag ? search.tag : null;

        let data = {
            'limit': _limit,
            'offset': _offset,
            'orderby': _sorter,
            'state': _filter,
            'google': _google,
            'nostats': true
        };
        // TODO: only load the tag on the initial load
        if (tag) {
            data.groupId = tag;
        }
        await runAction('get_all_members', data, 
            (result) => {
                if (result) {
                    let fullCount = result.members.length > 0 ? result.members[0].fullCount : 0;
                    setMembers(result.members);
                    setFullCount(fullCount);
                    setLimit(_limit);
                    setOffset(_offset);
                    setFilter(_filter);
                    setGooogle(_google);
                    setSorter(_sorter);
                    setLoadingMembers(false);
                }
            });
    }

    const deleteMembers = async (memberIds) => {
        const data = {'members': memberIds};
        await runAction('delete_member', data, 
            (result) => {
                if (result) {
                    loadMembers({});
                    publish('notification', result.message);
                }
            });
    }

    const importMembers = async (map, objects, callback) => {
        const data = {map, objects};
        await runAction('import_members', data, 
            (result) => {
                if (result) {
                    callback();
                    loadMembers({});
                    publish('notification', result.message);
                }
            });
    }

    const createMember = async (newMember) => {
        const data = Object.assign({}, newMember);
        await runAction('create_member', data, 
            (result) => {
                if (result) {
                    loadMembers({});
                }
            });
    }

    const bulkUpdate = async (data, callback) => {
        await runAction('bulk_member_update', data, () => {
            loadMembers({});
            callback();
        });
    }

    const provider = {
        loadingMembers,
        members,
        limit,
        offset,
        fullCount,
        loadMembers,
        deleteMembers,
        importMembers,
        createMember,
        bulkUpdate
    }

    return <MembersContext.Provider value={provider}>{children}</MembersContext.Provider>
}

function useMembers() {
    const context = React.useContext(MembersContext);
    if (!context) {
        throw Error('useMembers must be used with a MembersProvider');
    }
    return context;
}

export { MembersProvider, useMembers }
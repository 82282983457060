import React, { useRef, useState } from 'react';
import { publish } from 'pubsub-js';
import 'style/csvImport.scss';

function CsvImport({targetFields, onImport}) {
    const [totalRows, setTotalRows] = useState(0);
    const [totalColumns, setTotalColumns] = useState(0);
    const [acceptedRows, setAcceptedRows] = useState([]);
    const [rejectedRows, setRejectedRows] = useState([]);
    const [fields, setFields] = useState([]);
    const [map, setMap] = useState({});
    const fileInputRef = useRef(null);
    const elementsAreaRef = useRef(null);

    const handleDragOver = (event) => {
        event.dataTransfer.dropEffect = 'copy';
        event.stopPropagation();
        event.preventDefault();
    }
    const handleDrop = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const files = event.dataTransfer.files;
        fileUpload(files);
    }
    const openFileChooser = () => {
        fileInputRef.current.click();
    }
    const inputHandler = () => {
        fileUpload(fileInputRef.current.files);
    }
    const fileUpload = (files) => {
        // eslint-disable-next-line no-cond-assign
        for (let i = 0, f; f = files[i]; i++) {
            const name = escape(f.name);
            console.log("File Info: " 
						+ "Name: " + name + ", " 
						+ "Type: " + (f.type || 'n/a') + ", "
						+ "Size: " + f.size + " bytes, " 
						+ "last modified: " + (f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : "n/a"));
            if (f.type === "text/csv" || name.substring(name.length - 4).toLowerCase() === ".csv") {
                const reader = new FileReader();
                reader.onload = handleFileRead;
                reader.readAsText(f);
            } else {
                publish('error', name + " is not a valid csv file.");
            }
        }
    }
    const handleFileRead = (event) => {
        // get the results from the event
        const result = event.target.result;
        // determine line separator in data
        const lineSep = result.indexOf('\r\n') ? '\r\n' : result.indexOf('\n') > -1 ? '\n' : '\r';
        // split the line up into an array
        const lines = result.split(lineSep);
        setTotalRows(lines.length);
        // function to remove quotes
        const removeQuotes = function(str) {
            if (str.length > 0 && str[0] === '"' && str[str.length - 1] === '"') {
                return str.substring(1, str.length - 1).trim();
            }
            return str.trim();
        };
        // function to split CSV line considering quotes
        const splitCSVLine = function(line) {
            const regex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;
            return line.split(regex).map(removeQuotes);
        };
        // go through each line
        let keys = null, columnCount = 0, acceptedRowArr = [], rejectedRowArr = [];
        let values = null;
        lines.forEach((line, i) => {
            console.log(line);
            if (line.length > 0) {
                // assume the first line is the csv keys
                if (i === 0) {
                    keys = splitCSVLine(line);
                    columnCount = keys.length; 
                } else {
                    let lineValues = splitCSVLine(line);
                    console.log('numLineValues' + lineValues.length);
                    if (values === null) {
                        // create an object using the keys and values
                        values = lineValues;
                    } else {
                        values = values.concat(lineValues);
                    }
                    console.log('numValues:' + values.length);
                    // if we have too many values, pop them off the end
                    if (values.length > keys.length) {
                        rejectedRowArr.push(i);
                        values = null;
                        return;
                    }
                    if (values.length === keys.length) {
                        let obj = {};
                        keys.forEach((key, j) => {
                            key = key.split(' ').join('_');
                            let value = removeQuotes(values[j].trim());
                            obj[key.trim()] = value;
                        });
                        // add the object to the members array
                        acceptedRowArr.push(obj);
                        values = null;
                    } else {
                        // add in the next line of values
                        console.log('reading next row');
                    }
                }
            }
        });
        setTotalColumns(columnCount);
        setAcceptedRows(acceptedRowArr);
        setRejectedRows(rejectedRowArr);
        // format the keys for use in the field map widget
        const sourceFields = keys.map((key) => {return {id: key.split(' ').join('_'), label: key.trim()};});
        setFields(sourceFields);
    }
    const mapField = (sourceField, targetFieldId) => {
        console.log(targetFieldId + ' ' + sourceField.id);
        let _map = {...map};
        if (sourceFieldIsLinked(sourceField.id) || targetFieldId === '0') {
            const currentTargetId = targetIdForSource(sourceField.id);
            if (currentTargetId) {
                delete _map[currentTargetId];
            }
        }
        if (targetFieldId !== '0') {
            _map[targetFieldId] = sourceField.id;
        }
        setMap(_map);
    }
    const sourceFieldIsLinked = (sourceId) => {
        const valueArray = Object.values(map);
        if (valueArray && valueArray.length > 0) {
            return valueArray.findIndex(elem => elem === sourceId) > -1;
        }
        return false;
    }
    const targetFieldIsLinked = (targetId) => {
        return map[targetId];
    }
    const targetIdForSource = (sourceId) => {
        for (const [key, value] of Object.entries(map)) {
            if (value === sourceId) {
                return key;
            }
        }
        return null;
    }
    const importHandler = () => {
        onImport(map, acceptedRows);
    }
    const selectDifferentFileHandler = () => {
        setTotalRows(0);
        setTotalColumns(0);
        setAcceptedRows([]);
        setRejectedRows([]);
        setFields([]);
        setMap({});
    }
    return (
        <div className='csvImportOuter'>
            <div className='csvImportInner'>
                <div className='csvFileImportArea'>
                    {fields.length === 0 ? 
                    <>
                    <p>Drag and drop a CSV file into the area below to begin. The CSV file must have a header row.</p>
                    <div className='drop-zone' onDragOver={handleDragOver} onDrop={handleDrop}>
                        <button onClick={openFileChooser}>Choose File</button>
                        <br/>or
                        <br/>Drop Files Here
                    </div>
                    <div>
                        <input type='file' className='file-input' onInput={inputHandler} ref={fileInputRef}/>
                    </div>
                    </>
                    : <div className='selectDifferentFile'><button onClick={selectDifferentFileHandler}>Select different file</button></div>}
                </div>
                <div className='csvElementsArea' ref={elementsAreaRef}>
                    {fields.length === 0 ? <div className='noFieldsMessage'>Select a file above to begin</div> :
                        <>
                        <p>For each of the csv columns on the left that you want to import, select the membership 
                            field from the menu on the right that the data should be imported to.</p>
                        <div className='csvElementsHeader'><h5>CSV Column</h5><h5>Member Data</h5></div>
                        <ul>
                            {fields.map((field, idx) => 
                                <li key={idx}>
                                    <div className='source'>{`${field.label}`}</div>
                                    <div className='target'>
                                        <select onChange={(evt) => mapField(field, evt.target.value)}>
                                            <option value='0'>-Do not import-</option>
                                            {targetFields.map(target => 
                                                <option key={target.id} value={target.id} disabled={targetFieldIsLinked(target.id)}>
                                                    {`${target.label} (${target.hint})`}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </li>)}
                        </ul>
                        <div className='buttonsHolder' style={{margin:'10px 0 10px 10px'}}>
                            <button onClick={importHandler} disabled={Object.values(map).length === 0}>Import</button>
                        </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default CsvImport;
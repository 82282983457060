import React, { useEffect, useState } from 'react';
import Timezones from 'components/timezones';
import Countries from 'components/countries';
import Features from './features';
import Logins from 'routes/systemAdmin/logins/logins';
import AccountSettings from './accountSettings';
import Receipts from './receipts';
import Subscription from './subscription';
import PhoneSetup from './phoneSetup';
import APIKeys from './apiKeys';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import SubscriptionInvoices from './subscriptionInvoices';
import { useAuth } from 'contexts/auth';
import Loading from 'components/loading';
import AccountMessages from 'apps/accountMessages/AccountMessages';
import { CountriesProvider } from 'contexts/countries';
import { TimezonesProvider } from 'contexts/timezones';
import { LoginsProvider } from 'contexts/logins';
import { AccountMessagesProvider } from 'contexts/accountMessages';
import { useTenant } from 'contexts/tenant';
import { FeaturesProvider } from 'contexts/features';
import { ReceiptsProvider } from 'contexts/receipts';
import { TenantSettingsProvider } from 'contexts/tenantSettings';
import { StripeSettingsProvider } from 'contexts/stripeSettings';
import { ApiKeysProvider } from 'contexts/apiKeys';
import { PermissionsProvider } from 'contexts/permissions';
import { SubscriptionProvider } from 'contexts/subscription';
import { SubscriptionTypesProvider } from 'contexts/subscriptionTypes';
import { SubscriptionInvoicesProvider } from 'contexts/subscriptionInvoices';

const tabLabels = ['Logins','Features','Account Settings','Phone Numbers','Receipts','Subscription',
    'Invoices', 'API Access'];
const groupTabLabels = ['Logins','Features','Messages','Phone Numbers','API Access', 'Subscription'];

function AccountDetails({onHide, groups}) {
    const { group } = useAuth();
    const { tenant, saveTenant } = useTenant();
    const [tablet, setTablet] = useState(0);
    const [name, setName] = useState('');
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [isoCountry, setIsoCountry] = useState('');
    const [zip, setZip] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [timezone, setTimezone] = useState('');
    const [phone, setPhone] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const [affiliate, setAffiliate] = useState('');
    const [status, setStatus] = useState('1');
    const [customerType, setCustomerType] = useState(0);
    const [features, setFeatures] = useState([]);
    const [twilioNumber, setTwilioNumber] = useState({endpoint: null});

    useEffect(() => {
        if (tenant) {
            setName(tenant.name);
            setAddress1(tenant.address1);
            setAddress2(tenant.address2);
            setCity(tenant.city);
            setState(tenant.state);
            setIsoCountry(tenant.isoCountry);
            setZip(tenant.zip);
            setContact(tenant.contact);
            setEmail(tenant.email);
            setTimezone(tenant.timezone);
            setPhone(tenant.phone);
            setSubdomain(tenant.subdomain);
            setAffiliate(tenant.affiliate);
            setStatus(tenant.status);
            setCustomerType(tenant.customerType);
            setFeatures(tenant.features);
            setTwilioNumber(tenant.twilioNumber);
        }
    }, [tenant]);
    
    const changeFeatureHandler = (feature, isEnabled) => {
        let _features = [...features];
        if (isEnabled) {
            _features.push({id: feature.id, label: feature.label})
        } else {
            let idxToRemove = _features.find((f) => f.id === feature.id);
            if (idxToRemove !== -1) {
                _features.splice(idxToRemove, 1);
            }
        }
        setFeatures(_features);
    }

    const handleSettingsChange = (key, value) => {
        if (key === 'phoneNumber') {
            setPhone(value);
        } else if (key === 'twilioNumber') {
            setTwilioNumber(value);
        }
    }

    const save = () => {
        let data = {...tenant, name, address1, address2, city, state, zip, isoCountry, contact, email,
            timezone, phone, subdomain, affiliate, status, customerType, features, twilioNumber};
        saveTenant(data, () => {onHide();});
    }
    const selectTablet = (index) => {
        setTablet(index);
    }
    
    return (
        <div>
            {tenant ?
            <div className="contact-details">
                <div className="contact-settings-panel">
                    <div className="breadcrumb" onClick={onHide}>
                        <div className="breadcrumb-label">
                            <FontAwesomeIcon icon={faAngleLeft} /> Accounts
                        </div>
                    </div>
                    <div className="contact-name">{tenant.name}</div>
                    <div className='contact-id'>{tenant.uuid}</div>
                    <hr/>
                    <label>Name</label>
                    <input type="text" value={name} onChange={e => setName(e.target.value)} maxLength="64"/>
                    <label>Address</label>
                    <input type="text" value={address1} onChange={e => setAddress1(e.target.value)} maxLength="64"/>
                    <label>Address</label>
                    <input type="text" value={address2} onChange={e => setAddress2(e.target.value)} maxLength="64"/>
                    <label>City</label>
                    <input type="text" value={city} onChange={e => setCity(e.target.value)} maxLength="64"/>
                    <label>State</label>
                    <input type="text" value={state} onChange={e => setState(e.target.value)} maxLength="64"/>
                    <label>Country</label>
                    <CountriesProvider>
                        <Countries value={isoCountry} onChange={e => setIsoCountry(e.target.value)} />
                    </CountriesProvider>
                    <label>Zip</label>
                    <input type="text" value={zip} onChange={e => setZip(e.target.value)} maxLength="64"/>
                    <label>Contact</label>
                    <input type="text" value={contact} onChange={e => setContact(e.target.value)} maxLength="64"/>
                    <label>Email</label>
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} maxLength="64"/>
                    <label>Timezone</label>
                    <TimezonesProvider>
                        <Timezones value={timezone} onChange={e => setTimezone(e.target.value)} />
                    </TimezonesProvider>
                    <label>Subdomain</label>
                    <input type="text" value={subdomain} onChange={e => setSubdomain(e.target.value)} maxLength="64"/>
                    {!group &&
                        <>
                            <label>Group</label>
                            <select value={affiliate} onChange={e => setAffiliate(e.target.value)}>
                                <option value='0'>None</option>
                                {groups.map(g => <option key={g.id} value={g.id}>{g.name}</option>)}
                            </select>
                            <label>Customer type</label>
                            <select value={customerType} onChange={e => setCustomerType(e.target.value)}>
                                <option value='0'>Car Club</option>
                                <option value='1'>Car Show</option>
                            </select>
                        </>
                    }
                    <label>Status</label>
                    <select value={status} onChange={e => setStatus(e.target.value)}>
                        <option value="1">Active</option>
						<option value="2">Inactive</option>
						<option value="3">Review</option>
						<option value="4">Trial</option>
						<option value="5">Default</option>
                        <option value="6">Initial</option>
                        <option value="7">Initial (no email)</option>
						<option value="90">Comp</option>
                    </select>
                    <div className="button-container">
                        <button onClick={save}>Save</button>
                    </div>
                </div>
                <div className="contact-communication-panel" style={{width:'75%'}}>
                    <div className="tablets-outer">
                        <div className="tablets-inner">
                            {!group ? 
                                tabLabels.map((l, i) => 
                                    <div key={i} className={'tablet' + (tablet === i ? ' selected' :'')} onClick={() => selectTablet(i)}>{l}</div>
                                )
                                :
                                groupTabLabels.map((l, i) => 
                                    <div key={i} className={'tablet' + (tablet === i ? ' selected' :'')} onClick={() => selectTablet(i)}>{l}</div>
                                )
                            }
                        </div>
                    </div>
                    <div className="communication-area-outer">
                        <div className="communication-area-inner">
                            {!group ?
                                tablet === 0 ? <LoginsProvider type='account' resourceId={tenant.id}><Logins type='account' resourceId={tenant.id} /></LoginsProvider> :
                                tablet === 1 ? <FeaturesProvider><Features features={features} onChange={changeFeatureHandler} /></FeaturesProvider> :
                                tablet === 2 ? 
                                    <TenantSettingsProvider tenantId={tenant.id}>
                                        <StripeSettingsProvider tenantId={tenant.id}>
                                            <AccountSettings />
                                        </StripeSettingsProvider>
                                    </TenantSettingsProvider> :
                                tablet === 3 ? <PhoneSetup tenantId={tenant.id} 
                                        phoneNumber={phone} 
                                        twilioNumber={twilioNumber.endpoint || ''}
                                        handleSettingsChange={handleSettingsChange} /> :
                                tablet === 4 ? <ReceiptsProvider tenantId={tenant.id}>
                                        <Receipts tenantId={tenant.id} /></ReceiptsProvider> :
                                tablet === 5 ? 
                                    <SubscriptionTypesProvider groupId={affiliate}>
                                        <SubscriptionProvider tenantId={tenant.id}>
                                            <Subscription />
                                        </SubscriptionProvider>
                                    </SubscriptionTypesProvider> : 
                                tablet === 6 ? 
                                    <SubscriptionInvoicesProvider tenantId={tenant.id}>
                                        <SubscriptionInvoices tenantId={tenant.id} />
                                    </SubscriptionInvoicesProvider> :
                                tablet === 7 ? 
                                    <PermissionsProvider>
                                        <ApiKeysProvider tenantId={tenant.id}>
                                            <APIKeys tenantId={tenant.id} />
                                        </ApiKeysProvider>
                                    </PermissionsProvider> : null
                                :
                                tablet === 0 ? <LoginsProvider type='account' resourceId={tenant.id}><Logins type='account' resourceId={tenant.id} /></LoginsProvider> :
                                tablet === 1 ? <FeaturesProvider><Features features={features} onChange={changeFeatureHandler} /></FeaturesProvider> :
                                tablet === 2 ? <AccountMessagesProvider tenantId={tenant.id}><AccountMessages /></AccountMessagesProvider> :
                                tablet === 3 ? <PhoneSetup tenantId={tenant.id} 
                                        phoneNumber={phone} 
                                        twilioNumber={twilioNumber.endpoint || ''}
                                        handleSettingsChange={handleSettingsChange} /> :
                                tablet === 4 ? 
                                    <PermissionsProvider>
                                        <ApiKeysProvider tenantId={tenant.id}>
                                            <APIKeys tenantId={tenant.id} />
                                        </ApiKeysProvider>
                                    </PermissionsProvider> : 
                                tablet === 5 ? 
                                    <SubscriptionTypesProvider groupId={affiliate}>
                                        <Subscription />
                                    </SubscriptionTypesProvider> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            : <Loading />
            }
        </div>
    )
}

export default AccountDetails;
import AmazingTable from 'components/amazingTable';
import { TableSetupProvider } from 'contexts/tableSetup';
import { useVoters } from 'contexts/voter';
import React, { useState } from 'react';
import moment from 'moment';
import SidePanel from 'components/sidePanel';
import { useAuth } from 'contexts/auth';
import Modal from 'components/modal';
import { publish } from 'pubsub-js';

const tableColumns = [
    {id: 'id', label:'Id', display: false, datum: 'id'},
    {id: 'uuid', label:'Uuid', display: true, datum: 'uuid'},
    {id: 'eventId', label:'Event Id', display: false, datum: 'eventId'},
    {id: 'batch', label: 'Batch Name', display: true, datum: 'batch'},
    {id: 'created', label:'Created', display: false, formatter: function(obj) {return moment(new Date(obj.created)).format('ddd, MMM Do YYYY, h:mm a');}},
    {id: 'fname', label:'First Name', style:{width:'100px'}, display: true, datum: 'fname'},
    {id: 'lname', label:'Last Name', style:{width:'100px'}, display: true, datum: 'lname'},
    {id: 'email', label:'Email', display: true, datum: 'email'},
    {id: 'voted', label:'Voted', display: true, formatter: function(obj) {return obj.voted ? moment(new Date(obj.voted)).format('ddd, MMM Do YYYY, h:mm a') : 'N/A';}},
];

function MobileVotingBallots({eventId}) {
    const { votersLoading, voters, loadVoters, generateVoters, deleteVoters } = useVoters();
    const { tenant } = useAuth();
    const detailsPanelRef = React.createRef(null);
    const [voter, setVoter] = useState(null);
    const [showCreateVotersDialog, setShowCreateVotersDialog] = useState(false);
    const [votersToGenerate, setVotersToGenerate] = useState(0);
    const [batchName, setBatchName] = useState('');
    const [showPrintDialog, setShowPrintDialog] = useState(false);
    const [batchToPrint, setBatchToPrint] = useState('');

    const generateVotersHandler = () => {
        if (batchName === '') {
            publish('error', 'Please choose a batch name');
            return;
        }
        generateVoters(votersToGenerate, batchName);
        setShowCreateVotersDialog(false);
        setVotersToGenerate(0);
        setBatchName('');
    }

    const removeVoters = (voters) => {
        deleteVoters(voters.map(t => t.id));
    }

    const printBallotsHandler = () => {
        setShowPrintDialog(true);
    }
    const printHandler = () => {
        setShowPrintDialog(false);
        const url = '/voterballots/' + eventId + '/' + tenant.subdomain + '/' + encodeURI(batchToPrint);
        window.open(url, '_blank', 'noopener noreferrer');
        setBatchToPrint('');
    }

    const voterClickHandler = (item) => {
        detailsPanelRef.current.show();
        setVoter(item);
    }

    const closeDetails = () => {
        setVoter(null);
        detailsPanelRef.current.hide();
    }

    const getBatchNames = () => {
        let batchNames = [];
        voters.forEach(vtr => {
            if (batchNames.indexOf(vtr.batch) === -1) {
                batchNames.push(vtr.batch);
            }
        })
        return batchNames;
    }

    return (
        <>
            <div>
                <div style={{margin:'10px auto',border:'1px solid gray',borderRadius:'5px',padding:'20px',width:'200px',textAlign:'center'}}>{`${voters.length} Total / ${voters.filter(v => v.voted !== null).length} Voted`}</div>
                <TableSetupProvider tableName='voters' defaultColumns={tableColumns}>
                    <AmazingTable 
                        rows={voters}
                        onRefresh={loadVoters}
                        onCreate={() => setShowCreateVotersDialog(true)}
                        onCreateLabel='Add ballots'
                        onDelete={removeVoters}
                        onPrint={printBallotsHandler}
                        onRowClick={voterClickHandler}
                        loading={votersLoading}
                    />
                </TableSetupProvider>
                <SidePanel title='Ballot' ref={detailsPanelRef} onHide={() => setVoter(null)}>
                    {voter && <VoterDetails voter={voter} onSave={closeDetails} />}
                </SidePanel>
                <Modal 
                    title='Add voters'
                    showing={showCreateVotersDialog}
                    onHide={() => {setShowCreateVotersDialog(false);setVotersToGenerate(0);}}
                    buttons={[
                        {   type: 'primary', 
                            handler: () => {generateVotersHandler()},
                            disabled: votersToGenerate == 0,
                            label:'Generate'
                        }
                    ]}>
                    <p style={{width:'400px'}}>Enter in the number of mobile ballots you would like to add, up to 1000,
                        and a batch name, then click the generate button. You can then use the print button 
                        to print the batch of ballots.</p>
                    <div style={{display:'flex',flexFlow:'column nowrap'}}>
                        <label>Number of ballots</label>
                        <input type='number' value={votersToGenerate} onChange={e => setVotersToGenerate(e.target.value)} min={0} max={1000} />
                        <label>Batch name</label>
                        <select value={batchName} onChange={e => setBatchName(e.target.value)}>
                            <option value=''>-- Choose One --</option>
                            {['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'].map(ltr => 
                                <option key={ltr} value={`Batch ${ltr}`} disabled={voters.findIndex(v => v.batch === `Batch ${ltr}`) > -1}>{`Batch ${ltr}`}</option>)}
                        </select>
                    </div>
                </Modal>
                <Modal
                    title='Print batch'
                    showing={showPrintDialog}
                    onHide={() => setShowPrintDialog(false)}
                    buttons={[
                        {type: 'primary', handler: () => {printHandler()}, disabled: batchToPrint == '', label: 'Print'}
                    ]}>
                    <p>Select which batch of mobile ballots you would like to print.</p>
                    <select value={batchToPrint} onChange={e => setBatchToPrint(e.target.value)}>
                        <option value=''>--Select One--</option>
                        {getBatchNames().map(name => <option value={name}>{name}</option>)}
                    </select>
                </Modal>
            </div>
        </>
    )
}

function VoterDetails({voter, onSave}) {
    const [fname, setFname] = useState(voter.fname);
    const [lname, setLname] = useState(voter.lname);
    const [email, setEmail] = useState(voter.email);

    const saveHandler = () => {
        onSave();
    }

    return (
        <div className='contact-preview'>
            <label>Uuid</label>
            <div>{voter.uuid}</div>
            <label>Batch</label>
            <div>{voter.batch}</div>
            <label>Created</label>
            <div>{moment(voter.created).format('YYYY-MM-DD')}</div>
            <label>Voted</label>
            <div>{voter.voted ? moment(voter.voted).format('YYYY-MM-DD') : 'N/A'}</div>
            <label>First name</label>
            <input type='text' value={fname} onChange={(e) => setFname(e.target.value)} maxLength={64} />
            <label>Last name</label>
            <input type='text' value={lname} onChange={(e) => setLname(e.target.value)} maxLength={64} />
            <label>Email</label>
            <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} maxLength={64} />

            <div className='buttonsHolder' style={{marginTop:'5px'}}>
                <button onClick={saveHandler}>Save</button>
            </div>
        </div>
    )
}

export default MobileVotingBallots;
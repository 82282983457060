import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const EventMapContext = React.createContext(null);

function EventMapsProvider({eventId, children}) {
    const [mapsLoading, setMapsLoading] = useState(true);
    const [maps, setMaps] = useState([]);
    const { runAction } = useCore();

    useEffect(() => {
        loadEventMaps();
    }, []);

    const loadEventMaps = async () => {
        setMapsLoading(true);
        await runAction('get_event_maps', {eventId}, response => {
            setMaps(response.maps);
            setMapsLoading(false);
        })
    }

    const provider = {
        eventId,
        mapsLoading,
        maps,
        loadEventMaps,
    }

    return <EventMapContext.Provider value={provider}>{children}</EventMapContext.Provider>
}

function useEventMaps() {
    const context = React.useContext(EventMapContext);
    if (!context) {
        throw new Error('useEventMap must be used within an EventMapProvider');
    }
    return context;
}

export { EventMapsProvider, useEventMaps }
import React from 'react';
import SidePanel from './sidePanel';
import WaitButton from 'components/waitButton';
import PubSub from 'pubsub-js';
import 'style/dataImport.scss';

class FieldMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sourceFields: this.props.sourceFields,
            targetFields: this.props.targetFields,
            selectedSource: null,
            selectedTarget: null,
            map: {}
        };
        this.selectSource = this.selectSource.bind(this);
        this.linkFields = this.linkFields.bind(this);
        this.importHandler = this.importHandler.bind(this);
    }
    // the user selects a source field
    selectSource(field, index) {
        const {selectedSource, selectedTarget, map} = this.state;
        // if this is already selected, de-select it
        if (selectedSource === field.id) {
            this.setState({selectedSource: null});
        } else {
            // select it and if it is mapped, select its target
            this.setState({selectedSource: field.id});
            if (selectedTarget && map[selectedTarget]) {
                this.setState({selectedTarget: null});
            }
            let mapping = this.targetMapping(field.id);
            if (mapping) {
                this.setState({selectedTaget: mapping});
            }
            if (map[field.id]) {
                this.setState({selectedTarget: map[field.id]});
            }
        }
    }
    // the user selects a target field
    selectTarget(field, index) {
        const {selectedTarget, map} = this.state;
        // if this is already selected, de-select it
        if (selectedTarget === field.id) {
            this.setState({selectedTarget: null});
        } else {
            // select it
            this.setState({selectedTarget: field.id});
            // if the selected source field is already mapped to something, de-select it
            if (map[field.id]) {
                this.setState({selectedSource: map[field.id]});
            }
        }
    }
    // the user selects the link button
    linkFields() {
        const {selectedSource, selectedTarget} = this.state;
        let map = Object.assign({}, this.state.map);
        if (selectedSource && selectedTarget) {
            if (map[selectedTarget]) {
                delete map[selectedTarget];
            } else {
                map[selectedTarget] = selectedSource;
            }
            this.setState({selectedSource: null, selectedTarget: null, map});
        } else {
            console.log('source and target fields not selected');
            PubSub.publish('warning', "You must select a source and target field to link them to eachother.");
        }
    }
    targetMapping(target) {
        const {map} = this.state;
        let mapping = false;
        for (const [key, val] of Object.entries(map)) {
            if (!mapping && val === target) {
                mapping = key;
            }
        }
        return mapping;
    }
    importHandler() {
        const {map} = this.state;
        this.props.onImport(map);
    }
    render() {
        const {sourceFields, targetFields, selectedSource, selectedTarget, map} = this.state;
        let sources = sourceFields ? sourceFields.map((f, i) => {
            let selected = selectedSource === f.id;
            let linked = selected && map[selectedTarget] && map[selectedTarget] === selectedSource;
            //let linked = selected && map[selectedSource];
            if (selected && this.targetMapping(f.id)) {
            //if (selected && map[selectedSource] && map[selectedSource] !== selectedTarget) {
                selected = false;
            }
            return <div key={f.id} 
                className={'field ' + (linked ? 'linked' : selected ? 'selected' : '')} 
                onClick={() => this.selectSource(f, i)}>{f.label}</div>
        }) : null;
        let targets = targetFields ? targetFields.map((f, i) => {
            let selected = selectedTarget === f.id;
            let linked = selected && map[selectedTarget];
            //let linked = selected && map[selectedSource] && map[selectedSource] === selectedTarget;
            if (selected && map[selectedTarget] && map[selectedTarget] !== selectedSource) {
            //if (selected && this.targetMapping(f.id)) {
                selected = false;
            }
            return <div key={f.id} 
                className={'field ' + (linked ? 'linked' : selected ? 'selected' : '')}
                onClick={() => this.selectTarget(f, i)}>{f.label}</div>
        }) : null;
        return (
            <div className='field-map'>
                <p>Pick a target field on the left and a CSV source field on the right, then select the Link
                button to pair them for the import. Non paired fields
                will be set to generated values and can be changed later. CSV fields that are not paired will be ignored.</p>
                <table><tbody>
                    <tr><th>Target Fields</th><th></th><th>CSV Source Fields</th></tr>
                    <tr>
                        <td>
                            <div className='fieldsNode'>{targets}</div>
                        </td><td>
                            <button onClick={this.linkFields}>Link</button>
                        </td><td>
                            <div className='fieldsNode'>{sources}</div>
                        </td>
                    </tr>
                </tbody></table>
                <div className='button-holder'>
                    <WaitButton onClick={this.importHandler} label='Import' waitMode={this.props.waitMode}/>
                </div>
            </div>
        );
    }
}

class DataImport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalRows: 0,
            totalColumns: 0,
            acceptedRows: [],
            rejectedRows: [],
            sourceFields: null,
            waitMode: false
        }
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.openFileChooser = this.openFileChooser.bind(this);
        this.inputHandler = this.inputHandler.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.handleFileRead = this.handleFileRead.bind(this);
        this.handleImport = this.handleImport.bind(this);
    }
    show() {
        this.sidePanel.show();
    }
    hide() {
        this.setState({totalRows: 0, totalColumns: 0, acceptedRows: [], rejectedRows: [], sourceFields: null, waitMode: false});
        this.sidePanel.hide();
    }
    handleDragOver(event) {
        event.dataTransfer.dropEffect = 'copy';
    }
    handleDrop(event) {
        let files = event.dataTransfer.files;
        this.fileUpload(files);
    }
    openFileChooser() {
        this.fileInput.click();
    }
    inputHandler() {
        this.fileUpload(this.fileInput.files);
    }
    fileUpload(files) {
        // eslint-disable-next-line no-cond-assign
        for (let i = 0, f; f = files[i]; i++) {
            let name = escape(f.name);
            console.log("File Info: " 
						+ "Name: " + name + ", " 
						+ "Type: " + (f.type || 'n/a') + ", "
						+ "Size: " + f.size + " bytes, " 
						+ "last modified: " + (f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : "n/a"));
            if (f.type === "text/csv" || name.substring(name.length - 4).toLowerCase() === ".csv") {
                let reader = new FileReader();
                reader.onload = this.handleFileRead;
                reader.readAsText(f);
            } else {
                PubSub.publish('error', name + " is not a valid csv file.");
            }
        }
    }
    handleFileRead(event) {
        // get the results from the event
        let result = event.target.result;
		// determine line separater in data
		let lineSep = result.indexOf('\r\n') ? '\r\n' : result.indexOf('\n') > -1 ? '\n' : '\r';
		// split the line up into an array
		let lines = result.split(lineSep);
	    let totalRows = lines.length;
        const removeQuotes = function(str) {
            if (str.length > 0 && str[0] === '"' && str[str.length - 1] === '"') {
                return str.substring(1, str.length - 1).trim();
            }
            return str.trim();
        };
        // function to split CSV line considering quotes
        const splitCSVLine = function(line) {
            const regex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;
            return line.split(regex).map(removeQuotes);
        };
        // go through each line
        let keys = null, totalColumns = 0, acceptedRows = [], rejectedRows = [];
        let values = null;
        lines.forEach((line, i) => {
            console.log(line);
            if (line.length > 0) {
                // assume the first line is the csv keys
                if (i === 0) {
                    keys = splitCSVLine(',');
                    totalColumns = keys.length; 
                } else {
                    let lineValues = splitCSVLine(line);
                    console.log('numLineValues' + lineValues.length);
                    if (values === null) {
                        // create an object using the keys and values
                        values = lineValues;
                    } else {
                        values = values.concat(lineValues);
                    }
                    console.log('numValues:' + values.length);
                    // if we have too many values, pop them off the end
                    if (values.length > keys.length) {
                        rejectedRows.push(i);
                        values = null;
                        return;
                    }
                    if (values.length === keys.length) {
                        let obj = {};
                        keys.forEach((key, j) => {
                            key = key.split(' ').join('_');
                            let value = removeQuotes(values[j].trim());
                            obj[key.trim()] = value;
                        });
                        // add the object to the members array
                        acceptedRows.push(obj);
                        values = null;
                    } else {
                        // add in the next line of values
                        console.log('reading next row');
                    }
                }
            }
        });
        this.setState({totalRows, totalColumns, acceptedRows, rejectedRows});
        // format the keys for use in the field map widget
        let sourceFields = keys.map((key) => {return {id: key.split(' ').join('_'), label: key.trim()};});
        this.setState({sourceFields});
    }
    handleImport(map) {
        this.setState({waitMode: true});
        const {acceptedRows} = this.state;
        this.props.onImport(map, acceptedRows);
    }
    render() {
        const {totalRows, totalColumns, acceptedRows, rejectedRows, sourceFields, waitMode} = this.state;
        let rejectedLines = rejectedRows.map((r) => <li key={r}>{r}</li>);
        return (
            <div>
                <SidePanel title={this.props.title} ref={(c) => this.sidePanel = c}>
                    <div className='data-import'>
                        <p>Drag and drop a CSV file into the area below to begin. CSV file must have a header row.</p>
                        <div className='drop-zone' onDragOver={this.handleDragOver} onDrop={this.handleDrop}>
				            <button onClick={this.openFileChooser}>Choose File</button>
				            <br/>or
				            <br/>Drop Files Here
                        </div>
                        <div>
                            <label>Total Lines:</label> <span>{totalRows}</span><br />
                            <label>Columns Found:</label> <span>{totalColumns}</span><br />
				            <label>Total Data Lines Found:</label> <span>{acceptedRows.length}</span><br />
				            <label>Rejected Lines</label>
                            <div>
                                <p>The data on or around these lines was rejected 
                                    because we found more data then columns. This 
                                    is usually due to additional commas in the data.</p>
                                <div className='rejected-lines'>
                                    <ul>{rejectedLines}</ul>
                                </div>
                            </div>
                        </div>
                        { sourceFields ? 
                        <FieldMap 
                            targetFields={this.props.targetFields} 
                            sourceFields={sourceFields} 
                            onImport={this.handleImport} 
                            waitMode={waitMode} />
                        : null}
                        <div>
                            <input type='file' className='file-input' onInput={this.inputHandler} ref={(c) => this.fileInput = c}/>
                        </div>
                    </div>
                </SidePanel>
            </div>
        );
    }
}

export default DataImport;
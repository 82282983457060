import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const MemberContext = React.createContext(null);

function MemberProvider({memberId, children}) {
    const { runAction } = useCore();
    const [member, setMember] = useState(null);
    const [emailSuppression, setEmailSuppression] = useState();
    const [suppressionLoading, setSuppressionLoading] = useState(true);

    useEffect(() => {
        if (memberId) {
            getMember();
            setEmailSuppression({
                blacklisted: false,
                sendGridBlock: false,
                sendGridBounce: false,
                sendGridSpam: false
            });
        }
    }, [memberId])

    const getMember = () => {
        const data = {'id': memberId};
        runAction('get_member', data, 
            (result) => {
                if (result) {
                    setMember(result);
                }
            });
    }

    const updateMember = (data, callback) => {
        runAction('update_member', data, 
            (result) => {
                if (result) {
                    if (result.success) {
                        callback();
                    } else {
                        publish('error', 'Error' + result.errorMessage);
                    }
                }
            });
    }

    const updateMemberId = (currentMemberId, newMemberId, callback) => {
        const data = {currentMemberId, newMemberId};
        runAction("update_member_id", data, response => {
            if (response && response.success) {
                callback(true);
                publish('success', 'Member number successfully changed');
            } else {
                callback(false);
            }
        })
    }

    const fixMemberSettings = (callback) => {
        runAction('fix_member_settings', {memberId}, (response) => {
            publish('success', 'Removed ' + response.deleted + ' settings for this member');
            callback();
        });
    }

    const checkEmail = async () => {
        setSuppressionLoading(true);
        await runAction('check_member_email', {memberId}, (response) => {
            setEmailSuppression(response);
            setSuppressionLoading(false);
        })
    }

    const resetEmail = async () => {
        setSuppressionLoading(true);
        await runAction('reset_member_email', {memberId}, (response) => {
            setEmailSuppression(response);
            setSuppressionLoading(false);
        })
    }

    const provider = {
        member,
        updateMember,
        updateMemberId,
        fixMemberSettings,
        checkEmail, resetEmail,
        emailSuppression, suppressionLoading,
    }

    return <MemberContext.Provider value={provider}>{children}</MemberContext.Provider>
}

function useMember() {
    const context = React.useContext(MemberContext);
    if (!context) {
        throw new Error('useMember must be used within a MemberProvider');
    }
    return context;
}

export { MemberProvider, useMember }
import React, { useEffect, useRef, useState } from 'react';
import AmazingTable from 'components/amazingTable';
import { formatCurrency } from '_base';
import SidePanel from 'components/sidePanel';
import CurrencyInput from 'react-currency-input-field';
import { useSubscriptionTypes } from 'contexts/subscriptionTypes';
import { TableSetupProvider } from 'contexts/tableSetup';

const tableColumns = [
    {id: 'id', label:'ID', display: false, datum: 'id'},
    {id: 'affiliateid', label:'Group ID', display: false, datum: 'affiliateId'},
    {id: 'label', label: 'Label', display: true, datum: 'label'},
    {id: 'amount', label: 'Monthly Amount', display: true, formatter: (obj) => formatCurrency(obj.amount)},
    {id: 'memberLimit', label: 'Member Limit', display: true, datum: 'memberLimit'},
]

function SubscriptionTypes() {
    const { types, getTypes } = useSubscriptionTypes();
    const [selectedType, setSelectedType] = useState(null);
    const typePanel = useRef(null);

    const selectType = (type) => {
        setSelectedType(type);
        typePanel.current.show();
    }

    const cancelHandler = () => {
        setSelectedType(null);
        typePanel.current.hide();
    }

    const saveHandler = () => {
        setSelectedType(null);
        typePanel.current.hide();
        getTypes();
    }

    return (
        <div>
            <TableSetupProvider tableName='subscriptiontypes' defaultColumns={tableColumns}>
                <AmazingTable 
                    rows={types}
                    onRefresh={getTypes}
                    onCreate={() => typePanel.current.show()}
                    onCreateLabel='Add subscription type'
                    onRowClick={selectType}
                    />
            </TableSetupProvider>
            <SidePanel title='Subscription Type' ref={typePanel} >
                <SubscriptionType 
                    type={selectedType}
                    onCancel={cancelHandler}
                    onSave={saveHandler} />
            </SidePanel>
        </div>
    )
}

export default SubscriptionTypes;

function SubscriptionType({type, onCancel, onSave}) {
    const { saveSubscriptionType } = useSubscriptionTypes();
    const [label, setLabel] = useState('');
    const [amount, setAmount] = useState(0);
    const [memberLimit, setMemberLimit] = useState(0);

    useEffect(() => {
        if (type) {
            setLabel(type.label);
            setAmount(type.amount);
            setMemberLimit(type.memberLimit)
        }
    }, [type])

    const handleSave = () => {
        const settings = {label, amount, memberLimit};
        saveSubscriptionType(settings, () => {
            onSave();
        });
    }

    return (
        <div className='contact-preview'>
            <label>Label</label>
            <input type='text' value={label} onChange={e => setLabel(e.target.value)} maxLength='64' />
            <label>Monthly Amount</label>
            <CurrencyInput prefix='$' value={amount} onValueChange={(value) => setAmount(value)} />
            <label>Member Limit</label>
            <CurrencyInput allowDecimals={false} allowNegativeValue={false} value={memberLimit} onValueChange={(value) => setMemberLimit(value)} />
            <div>
                <button onClick={onCancel}>Cancel</button>
                <button onClick={handleSave}>Save</button>
            </div>
        </div>
    )
}
import AmazingTable from 'components/amazingTable';
import { StripeAccountProvider, useStripeAccounts } from 'contexts/StripeAccount';
import { TableSetupProvider } from 'contexts/tableSetup';
import React from 'react';

function Stripe() {

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Stripe</div>
                </div>
            </div>
            <StripeAccountProvider>
                <Accounts />
            </StripeAccountProvider>
        </div>
    )
}

export default Stripe;

function Accounts() {
    const { loadingAccounts, loadAccounts, accounts, deleteAccount } = useStripeAccounts();

    const columns = [
        {id: 'id', label: 'ID', display: false, datum: 'id'},
        {id: 'account', label: 'Account', display: true, formatter: obj => obj.business_profile.name},
        {id: 'accountid', label: 'Account ID', display: true, formatter: obj => obj.metadata.MT_tenant},
        {id: 'type', label: 'Type', display: true, datum: 'type'},
    ];

    const removeAccount = (rows) => {
        const accountId = rows[0].id;
        deleteAccount(accountId);
    }
    const viewAccount = () => {}

    return (
        <TableSetupProvider tableName='stripeAccounts' defaultColumns={columns}>
            <AmazingTable 
                    rows={accounts}
                    onRefresh={loadAccounts}
                    loading={loadingAccounts}
                    onDelete={removeAccount}
                    onRowClick={viewAccount}
                />
        </TableSetupProvider>
    )
}
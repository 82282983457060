import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const EventDashcardContext = React.createContext(null);

function EventDashcardProvider({eventId, children}) {
    const { runAction } = useCore();
    const [loadingCards, setLoadingCards] = useState(true);
    const [dashcards, setDashcards] = useState([]);

    useEffect(() => {
        loadDashcards();
    }, [])

    const loadDashcards = async () => {
        setLoadingCards(true);
        await runAction('get_event_dashcards', {eventId}, response => {
            setDashcards(response.dashcards);
            setLoadingCards(false);
        })
    }

    const createDashcard = async (data) => {
        await runAction('create_event_dashcard', {...data, eventId}, response => {
            console.log('id ', response.id);
            loadDashcards();
        })
    }

    const updateDashcard = async (data) => {
        await runAction('update_event_dashcard', {...data, eventId}, response => {
            console.log('updated ', response.updated);
            loadDashcards();
        })
    }

    const deleteDashcards = async (ids) => {
        await runAction('delete_event_dashcard', {ids}, response => {
            console.log('deleted ', response.deleted);
            loadDashcards();
        })
    }

    const provider = {
        loadingCards,
        loadDashcards,
        dashcards,
        createDashcard,
        updateDashcard,
        deleteDashcards,
    }

    return <EventDashcardContext.Provider value={provider}>{children}</EventDashcardContext.Provider>
}

function useEventDashcard() {
    const context = React.useContext(EventDashcardContext);
    if (!context) {
        throw new Error('useEventDashcard must be used within an EventDashcardProvider');
    }
    return context;
}

export { EventDashcardProvider, useEventDashcard }
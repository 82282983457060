import React from 'react';
import Password from 'routes/systemAdmin/logins/password';
import { publish } from 'pubsub-js';
import { useAuth } from 'contexts/auth';
import Avatar from 'components/avatar';
import { useCore } from 'contexts/core';

function UserSetup() {
    const { user, tenant, refresh } = useAuth();
    const { runAction } = useCore();

    const changePassword = (password) => {
        let data = {password: password, username: user.email, userId: user.user_id, sendEmail: false};
        runAction('reset_password', data, 
            (response) => {
                if (response) {
                    publish('success', 'Password change successfully saved');
                }
            })
    }

    const changeAvatar = (image) => {
        let data = {id: user.user_id, avatar: image}
        runAction('update_avatar', data, response => {
            publish('success', 'Your avatar has been updated');
        });
    }

    const switchAccount = (accountId) => {
        if (tenant && accountId === tenant.id) {
            return;
        }
        refresh(accountId);
    }

    return (
        <div className='settings-panel'>
            <h4>Personal Info</h4>
            <hr/>
            <h5>Name</h5>
            <div>{user.name}</div>
            <h5>Email address</h5>
            <div>{user.email}</div>
            <h5>Avatar</h5>
                <Avatar 
                    width='100' 
                    height='100'
                    value={user.avatar} 
                    onChange={changeAvatar} />
            <h5>Change Password</h5>
            <Password onSave={changePassword} />
            <div className='spacer'></div>
            <h4>Switch Account</h4>
            <hr/>
            <select value={tenant?.id} onChange={e => switchAccount(e.target.value)}>
                {user.accounts.map(acct => 
                    <option key={acct.id} value={acct.id}>
                        {acct.name}
                    </option>)
                }
            </select>
        </div>
    )
}

export default UserSetup;
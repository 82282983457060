import { usePricingTeirs } from 'contexts/pricingTeirs';
import React, {useEffect, useState} from 'react';
import CurrencyInput from 'react-currency-input-field';

function PricingTeir({value, onSave}) {
    const { pricingTeirs } = usePricingTeirs();
    const [teir, setTeir] = useState(0);
    const [dayOfMonth, setDayOfMonth] = useState(1);
    const [price, setPrice] = useState(10);
    const [extension, setExtension] = useState(0);

    useEffect(() => {
        if (value) {
            setTeir(value.teir);
            setDayOfMonth(value.dayOfMonth);
            setPrice(value.price);
            setExtension(value.extension);
        } else {
            resetState();
        }
    }, [value])

    const resetState = () => {
        setTeir(0);
        setDayOfMonth(1);
        setPrice(0);
        setExtension(0);
    }

    const isDisabled = (teirValue) => {
        return pricingTeirs.findIndex(pt => pt.teir === teirValue) > -1 && teir !== teirValue;
    }

    const save = () => {
        let data = {
            teir: teir,
            dayOfMonth,
            price,
            extension
        }
        if (value) {
            data.id = value.id;
        }
        onSave(data);
        resetState();
    }

    return (
        <div>
            <p style={{width:'400px'}}>Set the month for this rule, the price the member pays when joining in this month, 
                the day of the month that the new membership extension should be applied to,
                and the number of months to extend the new membership by.</p>
            <div className='contact-preview'>
				<label>Rule month</label>
				<select value={teir} onChange={evt => setTeir(evt.target.value)}>
                    <option value='0'>-- Choose One --</option>
                    <option value='5' disabled={isDisabled(5)}>January</option>
                    <option value='6' disabled={isDisabled(6)}>February</option>
                    <option value='7' disabled={isDisabled(7)}>March</option>
                    <option value='8' disabled={isDisabled(8)}>April</option>
                    <option value='9' disabled={isDisabled(9)}>May</option>
                    <option value='10' disabled={isDisabled(10)}>June</option>
                    <option value='11' disabled={isDisabled(11)}>July</option>
                    <option value='12' disabled={isDisabled(12)}>August</option>
                    <option value='13' disabled={isDisabled(13)}>September</option>
                    <option value='14' disabled={isDisabled(14)}>October</option>
                    <option value='15' disabled={isDisabled(15)}>November</option>
                    <option value='16' disabled={isDisabled(16)}>December</option>
				</select>
                <label>New member price</label>
                <CurrencyInput prefix='$' value={price} onValueChange={(value) => setPrice(value)} />
                <label>Extend memberships on or after this day of the month (1 - 31)</label>
                <input type='number' value={dayOfMonth} onChange={evt => setDayOfMonth(evt.target.value)} min={1} max={31} />
                <label>Additional months to add to expiration (0 - 24)</label>
                <input type='number' value={extension} onChange={evt => setExtension(evt.target.value)} min={0} max={24} />
			</div>
			<div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
        </div>
    )
}

export default PricingTeir;
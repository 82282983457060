import React, { useState } from 'react';
import Toggle from 'components/toggle';
import Logins from 'routes/systemAdmin/logins/logins';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import SubscriptionTypes from './subscriptionTypes';
import GroupSettings from './groupSettings';

import { GroupProvider } from 'contexts/group';
import { LoginsProvider } from 'contexts/logins';
import { SubscriptionTypesProvider } from 'contexts/subscriptionTypes';
import { useAffiliates } from 'contexts/affiliates';
import { ChatDatabasesProvider } from 'contexts/chatDatabases';

const tabLabels = ['Logins','Settings', 'Account Subscription Types'];

function GroupDetails(props) {
    const { updateAffiliate } = useAffiliates();
    const [group, setGroup] = useState(props.group)
    const [tablet, setTablet] = useState(0);

    const handleChange = (event) => {
		let _group = Object.assign({}, group);
        _group[event.target.name] = event.target.value;
		setGroup(_group);
    }
    
    const saveHandler = () => {
        let data = {...group};
        updateAffiliate(data, () => {
            props.onHide();
        })
    }

    const selectTablet = (index) => {
        setTablet(index);
    }
    
    return (
        <div className='contact-details'>
            <div className='contact-settings-panel'>
                <div className='breadcrumb' onClick={props.onHide}>
                    <div className='breadcrumb-label'>
                        <FontAwesomeIcon icon={faAngleLeft} /> Groups
                    </div>
                </div>
                <div className='contact-name'>{group.name}</div>
                <hr />
                <label>Name</label>
                <input type='text' name='name' value={group.name} onChange={handleChange} maxLength='64' />
                <label>Administrator Name</label>
                <input type='text' name='admin' value={group.admin} onChange={handleChange} maxLength='64' />
                <label>Administrator Email</label>
                <input type='text' name='adminEmail' value={group.adminEmail} onChange={handleChange} maxLength='64' />
                <label>Active</label>
                <Toggle checked={group.active} />
                <div className='button-container'>
                    <button onClick={saveHandler}>Save</button>
                </div>
            </div>
            <div className='contact-communication-panel' style={{width:'75%'}}>
                <div className='tablets-outer'>
                    <div className='tablets-inner'>
                        {
                            tabLabels.map((l, i) => 
                                <div key={i} className={'tablet' + (tablet === i ? ' selected' :'')} onClick={() => selectTablet(i)}>{l}</div>
                            )
                        }
                    </div>
                </div>
                <div className='communication-area-outer'>
                    <div className='communication-area-inner'>
                        <GroupProvider id={group.uuid}>
                        {
                            tablet === 0 ? <LoginsProvider type='group' resourceId={group.id}><Logins type='group' resourceId={group.id} /></LoginsProvider> :
                            tablet === 1 ? <ChatDatabasesProvider><GroupSettings group={group} /></ChatDatabasesProvider> :
                            tablet === 2 ? <SubscriptionTypesProvider groupId={group.id}><SubscriptionTypes groupId={group.id} /></SubscriptionTypesProvider> : null
                        }
                        </GroupProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GroupDetails;
import React, {useEffect, useState} from 'react';
import Permissions from './permissions';
import Password from './password';
import Core from 'util/core';
import PubSub from 'pubsub-js';
import { PermissionsProvider } from 'contexts/permissions';
import { useLogins } from 'contexts/logins';

function Login(props) {
    const { saving } = useLogins();
    const [id, setId] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('0');
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        if (props.login) {
            setId(props.login.user_id);
            setName(props.login.name);
            setEmail(props.login.email);
            setStatus(props.login.status);
            setPermissions(props.login.permissions);
        } else {
            setId(null);
            setName('');
            setEmail('');
            setStatus('0');
            setPermissions([]);
        }
    }, [props.login])

    const changePermissionsHandler = (perm, isEnabled) => {
        let _permissions = [...permissions];
        if (isEnabled) {
            _permissions.push({id: perm.id, label: perm.label});
        } else {
            let idxToRemove = _permissions.findIndex((p) => p.id == perm.id);
            if (idxToRemove !== -1) {
                _permissions.splice(idxToRemove, 1);
            }
        }
        setPermissions(_permissions);
    }

    const saveHandler = () => {
        let data = {name, email, status, permissions};
        props.onSave(data);
    }

    const changePassword = (password) => {
        let data = {password: password, username: email, userId: id, sendEmail: false};
        Core.runAction('reset_password', data, 
            (response) => {
                if (response) {
                    PubSub.publish('success', 'Password change successfully saved');
                }
            })
    }
    
    const resetMfaHandler = () => {
        Core.runAction('reset_mfa', {userId: id}, 
            (response) => {
                if (response) {
                    PubSub.publish('success', 'Multi-factor authentication reset succeeded');
                }
            })
    }

    return (
        <div style={{width:'350px'}}>
            <label>Name</label>
            <div><input type="text" value={name} onChange={e => setName(e.target.value)} maxLength='128' /></div>
            <label>Email</label>
            <div><input type="text" value={email} onChange={e => setEmail(e.target.value)} maxLength='128' /></div>
            <label>Status</label>
            <div>
                <select value={status} onChange={e => setStatus(e.target.value)}>
                    <option value='0'>Inactive</option>
                    <option value='1'>Active</option>
                    <option value='2'>Temporary</option>
                    <option value='3'>Review</option>
                </select>
            </div>
            <PermissionsProvider context={props.context} resourceId={props.resourceId}>
                <Permissions 
                    permissions={permissions} 
                    context={props.context} 
                    resourceId={props.resourceId} 
                    onChange={changePermissionsHandler} />
            </PermissionsProvider>
            <div>
                <button onClick={saveHandler} disabled={saving}>{saving ? 'Saving...' : 'Save'}</button>
            </div>
            {id ? 
                <div>
                    <hr/>
                    <Password onSave={changePassword} />
                    <hr/>
                    <div>
                        <button onClick={resetMfaHandler}>Reset MFA</button>
                    </div>
                </div>
                :
                <>
                    <hr/>
                    <p>When you click the save button a new administrator login for this account will be created with a generated password
                    and two emails will be sent to the email address informing the user of the login and the generated password.</p>
                </>
            }
        </div>
    )
}

export default Login;
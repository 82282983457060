import { useCore } from 'contexts/core';
import React, { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

export async function loader({params}) {
    return params;
}

function Unsubscribe() {
    const loaderData = useLoaderData();
    const [message, setMessage] = useState('Unsubscribing....');
    const { runNoAuthAction } = useCore();
    let unsubscribing = false;

    useEffect(() => {
        if (loaderData && loaderData.email && loaderData.email.length > 0 && !unsubscribing) {
            if (window.confirm('Are you sure you want to unsubscribe ' + loaderData.email + '?')) {
                unsubscribing = true;
                const data = {email: loaderData.email, tenantId: loaderData.tenantId || 1};
                runNoAuthAction('unsubscribe', data, (response) => {
                    if (response && response.success) {
                        setMessage("The email address " + loaderData.email + " has been un-subscribed and will no longer receive emails from middletwin.");
                    } else {
                        setMessage("The email address " + loaderData.email + " is already unsubscribed.");
                    }
                    unsubscribing = false;
                });
            } else {
                setMessage('Unsubscribe cancelled, you can close this window.');
            }
        } else {
            setMessage('ERROR: Invalid or missing email');
        }
    }, [loaderData])

    return (
        <div>
            <div style={{textAlign:'center',marginTop:'100px',fontSize:'24px',fontWeight:'bold'}}>
                <p><span >{message}</span></p>
                <p>You can close this window when finished.</p>
            </div>
        </div>
    )
}

export default Unsubscribe;
import { useClasses } from 'contexts/classes';
import { useEventDashcard } from 'contexts/eventDashcard';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import 'style/eventDashcard.scss';
import 'style/print.scss';

function Paper({dashcardId, entryData, tenantId, eventId}) {
    const { loadingCards, dashcards } = useEventDashcard();
    const [boxes, setBoxes] = useState([]);

    useEffect(() => {
        if (!loadingCards) {
            const dashcard = dashcards.find(dc => dc.id == dashcardId);
            if (dashcard) {
                const theBoxes = JSON.parse(dashcard.content);
                console.log(theBoxes);
                setBoxes(theBoxes);
            }
        }
    }, [loadingCards])

    return (
        <div className='paper printable-area landscape' style={{position:'relative' }}>
                {boxes.map((box, index) => (
                    <div
                        key={index}
                        style={{
                            position: 'absolute',
                            display: 'inline-block',
                            boxSizing: 'border-box',
                            x: 0,
                            y: 0,
                            transform: `translate(${box.x}px, ${box.y}px)`,
                            width: box.width,
                            height: box.height,
                            zIndex: box.type === 'border' ? 0 : 1
                        }}
                    >
                        <Box box={box} entryData={entryData} tenantId={tenantId} eventId={eventId} />
                    </div>
                ))}
        </div>
    )
}

function Box({box, entryData, tenantId, eventId}) {
    const { classes } = useClasses();

    const getEntryData = (dataItem) => {
        let dataValue = '';
        switch (dataItem) {
            case 'carNumber' : dataValue = entryData.car_number; break;
            case 'carClass' : dataValue = `${entryData.car_class} - ${classes.find(c => c.id == entryData.car_class)?.description}`; break;
            case 'classNum' : dataValue = entryData.car_class; break;
            case 'classLabel' : dataValue = classes.find(c => c.id == entryData.car_class)?.description; break;
            case 'year' : dataValue = entryData.year; break;
            case 'make' : dataValue = entryData.make; break;
            case 'model' : dataValue = entryData.model; break;
            case 'trim' : dataValue = entryData.trim; break;
            case 'color' : dataValue = entryData.color; break;
            case 'engine' : dataValue = entryData.engine; break;
            case 'name' : dataValue = entryData.fname + ' ' + entryData.lname; break;
            case 'city' : dataValue = entryData.city; break;
            case 'state' : dataValue = entryData.state; break;
            case 'citystate' : dataValue = `${entryData.city}, ${entryData.state}`; break;
        }
        return dataValue;
    }

    return (
        <div className={`box`}>
            {box.type === 'image' &&
                <div className='image-box' style={{
                    backgroundImage:`url(${box.imageData})`, backgroundSize:box.position}} />
            }
            {box.type === 'text' && 
                <div style={{fontSize:box.fontSize+'px', fontWeight: box.fontWeight}}>{box.text}</div>
            }
            {box.type === 'line' && 
                <div className='line-box' style={{borderBottom:`${box.stroke}px ${box.style} ${box.color}`}}/>
            }
            {box.type === 'border' && 
                <div className='border-box' 
                    style={{
                        border:`${box.stroke}px ${box.style} ${box.color}`, 
                        borderRadius: box?.radius ? box.radius : 'none',
                    }}>
                        <div className='border-box-background' 
                            style={{
                                backgroundImage:`url(${box.imageData})`, 
                                backgroundSize: box.position,
                                opacity: box.opacity
                            }}
                        />
                    </div>
            }
            {box.type === 'registration' &&
                <div className='registration-box'
                    style={{fontSize:box.fontSize+'px', fontWeight: box.fontWeight}}>{getEntryData(box.dataItem)}</div>
            }
            {box.type === 'qrcode' &&
                <div className='qrcode-box'>
                    <div className='qrcode-instructions'>
                    Use the camera on your phone to vote for this car.
                    </div>
                    <div className='qrcode-code'>
                    <QRCode size='100' value={`https://app.middletwin.com/vote/${tenantId}/${eventId}/${entryData.car_number}`}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default Paper;